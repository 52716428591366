import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { APIMethod, TOAST_TIMEOUT } from "../../../../API/APIClient";
import Loader from "../../../../components/Loader";
import ModalPopup from "../../../../components/ModalPopUp";
import Pagination from "../../../../components/Pagination";
import { useToasts } from "react-toast-notifications";
import NoData from "../../../../components/NoDataFound";
import SearchNoDataFound from "../../../../components/SearchNoDataFound";
import ReactModal from "react-modal-resizable-draggable";
import moment from "moment";

const RfxStatuses = (props) => {
  const [getListData, setGetListData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [buttonLoader, setButtonLoader] = useState(false);
  const [formFields, setFormFields] = useState({
    code: "",
    name: "",
    description: "",
  });
  // Edit
  const [edit, setEdit] = useState("");
  const [id, setId] = useState("");
  // Delete
  const [deleteId, setDeleteId] = useState("");
  const [showModalPopup, setShowModalPopup] = useState(false);
  // page
  const [pageCount, setPageCount] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  {
    /* Added this code for  WOII-120. Divya. November 07 2023.. Code Start */
  }
  const [rowsPerPage, setRowsPerPage] = useState(10);
  {
    /* Divya. November 07 2023. Code End */
  }
  // Add modal box
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
    setFlag(false);
    setId("");
    setFormFields({ ...formFields, name: "", code: "", description: "" });
  };
  const handleShow = () => setShow(true);
  const [codeCount, setCodeCount] = React.useState(0);
  //--------date-Piicker----------
  const [startDate, setStartDate] = useState(new Date());
  const [flag, setFlag] = useState(false);
  // Toast
  const { addToast } = useToasts();
  const [adding, setAdding] = useState();
  const [editing, setEditing] = useState();
  const [deleting, setDeleting] = useState();
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    //Commenting the following code for WOII-120. Divya. November 07 2023.. Code Start
    //getRfxStatusList(1)
    //Commenting end. Divya. November 07 2023..
    //Added this code for  WOII-120. Divya. November 07 2023.. Code Start
    getRfxStatusList(currentPage);
    {
      /* Divya. November 07 2023. Code End */
    }
    var data = JSON.parse(localStorage.getItem("user_Role"));
    checkPermission(data);
    //Commenting the following code for WOII-120. Divya. November 07 2023.. Code Start
    //}, [])
    //Commenting end. Divya. November 07 2023..
    //Added this code for  WOII-120. Divya. November 07 2023.. Code Start
  }, [rowsPerPage, currentPage]);
  {
    /* Divya. November 07 2023. Code End */
  }

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const checkPermission = (data) => {
    const resObject = data.find((item) => item.name === "Organizational Setup");
    const valObject = resObject.access.find(
      (item) => item.name === "RFx Statuses"
    );
    setAdding(valObject.is_create);
    setEditing(valObject.is_update);
    setDeleting(valObject.is_delete);
  };

  const getRfxStatusList = (page, key) => {
    setLoading(true);
    const new_key = key === undefined ? "" : key;
    global.new_key = key === undefined ? "" : key;
    var method = "GET";
    //Commenting the following code for WOII-120. Divya. November 07 2023.. Code Start
    //const apifetcherObj = APIMethod(`/rfx-statuses/list/${page}?keyword=` + new_key, null, method)
    //Commenting end. Divya. November 07 2023..
    //Added this code for  WOII-120. Divya. November 07 2023.. Code Start
    const apifetcherObj = APIMethod(
      `/rfx-statuses/list/${page}?limit=${rowsPerPage}&keyword=` + new_key,
      null,
      method
    );
    {
      /* Divya. November 02 2023. Code End */
    }
    apifetcherObj
      .then((response) => {
        return Promise.all([response.status, response.json()]);
      })
      .then((res) => {
        let statusCode = res[0];
        let data = res[1];

        if (statusCode === 200) {
          setGetListData(data.list);
          if (page === 1) {
            setPageCount(data.count);
          }
          setCurrentPage(page);
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const formatDate = () => {
    var d = startDate,
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  };

  const save = () => {
    if (
      formFields.code === "" ||
      formFields.name === "" ||
      formFields.description === ""
    ) {
      setFlag(true);
      addToast("Please fill all the fields", {
        autoDismissTimeout: TOAST_TIMEOUT,
        appearance: "error",
        autoDismiss: true,
      });
    } else if (codeCount < 2) {
      addToast("RFx Status code should have min. 2 character", {
        autoDismissTimeout: TOAST_TIMEOUT,
        appearance: "info",
        autoDismiss: true,
      });
    } else {
      setButtonLoader(true);
      if (edit === "/") {
        var params = JSON.stringify({
          id: id,
          name: formFields.name,
          description: formFields.description,
          code: formFields.code,
          start_date: formatDate("Sun May 11,2014"),
        });
        var method = "PUT";
      } else {
        params = JSON.stringify({
          name: formFields.name,
          description: formFields.description,
          code: formFields.code,
          start_date: formatDate("Sun May 11,2014"),
        });
        method = "POST";
      }
      const apifetcherObj = APIMethod("/rfx-statuses", params, method);
      apifetcherObj
        .then((response) => {
          return Promise.all([response.status, response.json()]);
        })
        .then((res) => {
          let statusCode = res[0];
          let data = res[1];

          if (statusCode === 200) {
            handleClose();
            addToast(data.message, {
              autoDismissTimeout: TOAST_TIMEOUT,
              appearance: "success",
              autoDismiss: true,
            });
            setFormFields({
              ...formFields,
              name: "",
              code: "",
              description: "",
            });
            getRfxStatusList(currentPage);
            setButtonLoader(false);
            setFlag(false);
          } else {
            addToast(data.message, {
              autoDismissTimeout: TOAST_TIMEOUT,
              appearance: "error",
              autoDismiss: true,
            });
            setButtonLoader(false);
          }
        })
        .catch((error) => {
          setButtonLoader(false);
        });
    }
  };

  const _onDelete = (id) => {
    setDeleteId(id);
    isShowPopup(true);
  };

  const isShowPopup = (status) => {
    setShowModalPopup(status);
  };

  const _onEdit = (val) => {
    handleShow();
    setFormFields({
      ...formFields,
      name: val.name,
      code: val.code,
      description: val.description,
    });
    setCodeCount(val.code.length);
    setEdit("/");
    setId(val.id);
  };

  const Search = (key) => {
    getRfxStatusList(1, key);
  };

  const nextPage = (pageNumber) => {
    //Commenting the following code for WOII-120. Divya. November 07 2023.. Code Start
    //getRfxStatusList(pageNumber)
    //Commenting end. Divya. November 07 2023..
    //Added this code for  WOII-120. Divya. November 07 2023.. Code Start
    setCurrentPage(pageNumber);
    {
      /* Divya. November 02 2023. Code End */
    }
  };

  {
    /* Added this code for  WOII-120. Divya. November 02 2023.. Code Start */
  }
  const handleRowsPerPageChange = (selectedValue) => {
    setRowsPerPage(selectedValue);
  };
  {
    /* Divya. November 02 2023. Code End */
  }

  const handleChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    const formFieldsObject = {
      ...formFields,
      [name]: value,
    };
    setFormFields(formFieldsObject);
  };

  const getData = () => {
    getRfxStatusList(1);
  };

  return (
    <>
      <div className="page-wrapper">
        <div className="page-content">
          <div className="row mb-3 align-items-center justify-content-between">
            <div className="col-lg-5 col-md-12 d-md-flex mb-md-0 mb-2 mb-lg-0 mb-md-3">
              <h2 className="page-title mb-md-0">RFx Statuses</h2>
            </div>
            <div className="col-lg-5 col-md-12 text-right d-md-flex align-items-center justify-content-center">
              <div className="input-group page-cont-search mr-md-3 mb-md-0 mb-2">
                <input
                  type="search"
                  className="form-control"
                  placeholder="Search by name and description"
                  onChange={(event) => Search(event.target.value)}
                />
                <span className="input-group-text text-secondary">
                  <i className="icofont-search-1"></i>
                </span>
              </div>
            </div>
          </div>

          <div
            style={{
              height: "60vh",
              overflowY: "auto",
              marginBottom: "20px",
              resize: "vertical",
              overflow: "auto",
              maxHeight: "110vh"
            }}
          >
            <table
              className="table table-style-1 table-responsive-lg  table reports"
              id="reportTable"
            >
              <thead className="sticky-top" style={{ zIndex: 10 }}>
                <tr>
                  <th scope="col" style={{ textTransform: "none" }}>
                    RFx STATUS CODE
                  </th>
                  <th scope="col" style={{ textTransform: "none" }}>
                    RFx STATUS NAME{" "}
                  </th>
                  <th scope="col">Description</th>
                  <th scope="col">Effective Date</th>
                  <th scope="col" className="text-center" width="110">
                    {editing === true || deleting === true ? "Action" : null}
                  </th>
                </tr>
              </thead>
              <ModalPopup
                showModalPopup={showModalPopup}
                onPopupClose={isShowPopup}
                id={deleteId}
                endPoint={"/rfx-statuses/"}
                updateList={getData}
              ></ModalPopup>
              <tbody>
                {loading === false
                  ? getListData.map((val) => {
                    return (
                      <tr>
                        <td>{val.code}</td>
                        <td>{val.name}</td>
                        <td title={val.description}>
                          {val.description.length > 30
                            ? `${val.description.substring(0, 30)}...`
                            : val.description}
                        </td>
                        <td>
                          {val.start_date
                            ? moment(val.start_date, "YYYY-MM-DD").format(
                              "MM-DD-YYYY"
                            )
                            : null}
                        </td>
                        <td className="text-center">
                          {editing === true ? (
                            <button
                              type="button"
                              className="btn btn-action edit mr-1"
                              onClick={() => _onEdit(val)}
                            >
                              <i className="icofont-pencil-alt-2"></i>
                            </button>
                          ) : null}
                        </td>
                      </tr>
                    );
                  })
                  : null}
              </tbody>
            </table>
          </div>
          {getListData.length === 0 && loading === false && global.new_key ? (
            <SearchNoDataFound message={"No RFx Statuses Found"} />
          ) : getListData.length === 0 && loading === false ? (
            <NoData />
          ) : null}
          {<Loader isLoading={loading} />}
          <div className="text-md-right mt-10 pt-12">
            <nav aria-label="Page navigation">
              <Pagination
                pages={pageCount}
                nextPage={nextPage}
                currentPage={currentPage}
                pageCount={pageCount}
                //Added this code for  WOII-120. Divya. November 02 2023.. Code Start
                rowsPerPage={handleRowsPerPageChange}
              // Divya. November 02 2023. Code End
              />
            </nav>
          </div>
        </div>
      </div>

      {/* Add Modal Box */}
      <ReactModal
        initWidth={550}
        initHeight={"auto"}
        top={132.5}
        className={"contained-modal-title-vcenter"}
        onRequestClose={handleClose}
        isOpen={show}
        disableResize={true}
        disableKeystroke={true}
      >
        <div className="modal-header">
          <h5 className="modal-title" id="">
            {id ? "Edit" : "Add"} RFx Status
          </h5>
          <button type="button" className="close" onClick={handleClose}>
            <i className="icofont-close"></i>
          </button>
        </div>
        <div className="modal-body">
          <div className="row form-style">
            <div className="col-md-12">
              <div className="form-group">
                <label>RFx Status Code</label>
                <input
                  type="text"
                  className="form-control"
                  name="code"
                  value={formFields["code"]}
                  onKeyUp={(e) => setCodeCount(e.target.value.length)}
                  maxLength={5}
                  onChange={handleChange}
                  disabled={true}
                />
                <div className="d-flex flex-column">
                  <span className="character-count">{codeCount}/5</span>
                  <span className="text-danger">
                    {flag === true && formFields.code === ""
                      ? "Please Enter RFX Status Code"
                      : null}
                  </span>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group">
                <label>RFx Status Name</label>
                <input
                  type="text"
                  className="form-control"
                  name="name"
                  value={formFields["name"]}
                  onChange={handleChange}
                />
                <span className="text-danger">
                  {flag === true && formFields.name === ""
                    ? "Please Enter RFx Status Name"
                    : null}
                </span>
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group">
                <label>Description</label>
                <textarea
                  className="form-control h-auto"
                  id=""
                  cols="30"
                  rows="3"
                  name="description"
                  value={formFields["description"]}
                  onChange={handleChange}
                />
                <span className="text-danger">
                  {flag === true && formFields.description === ""
                    ? "Please Enter Description"
                    : null}
                </span>
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group">
                <label>Effective Start Date</label>
                <DatePicker
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  className="form-control"
                  placeholder="select Date"
                  dateFormat="MM-dd-yyyy"
                  minDate={new Date()}
                  scrollableYearDropdown
                  showYearDropdown
                />
              </div>
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-lg btn-brand-1 bg-danger"
            onClick={handleClose}
          >
            Cancel
          </button>
          <button
            type="button"
            className="btn btn-lg btn-brand-1 bg-success"
            onClick={() => save()}
            disabled={buttonLoader}
          >
            {buttonLoader && (
              <img
                src={
                  require("../../../../assets/css/images/button_loader.gif")
                    .default
                }
                alt="loading..."
                style={{ marginLeft: 5 }}
              />
            )}
            <span>Save</span>
          </button>
        </div>
      </ReactModal>
    </>
  );
};
export default RfxStatuses;
