import { useState } from 'react'
import { APIMethod } from '../API/APIClient'
import { Row } from 'react-bootstrap'

const Chat = (props) => {
    const [message, setMessage] = useState('')
    const [chats, setChats] = useState([])
    const [isLoading, setLoading] = useState(false)
    {/* Added this code for  WOII-104. Divya. October 03 2023.. Code Start */}
    const [isMinimized, setMinimized] = useState(false)
    {/* Divya. October 03 2023. Code End */}

    const messageStyle={
        fontWeight:'bold',
        color: '#044661',
    }
    {/* Added this code for  WOII-104. Divya. October 13 2023.. Code Start */}  
    const plusStyle = {
        fontSize: '20px',
        lineHeight:'0.6'
      };
      
    const minusStyle = {
        fontSize: '30px'
      };
    {/* Divya. October 13 2023. Code End */}
    const onSend = () => {
        setLoading(true)
        console.log(message)
        console.log(props.id)
        var method = 'POST'
        var params = JSON.stringify({
            question: message,
        })
        const apifetcherObj = APIMethod(`/pre-rfx/chat-bot/${props.id}`, params, method)
        apifetcherObj
            .then(response => { return Promise.all([response.status, response.json()])})
            .then(res => {
                let statusCode = res[0]
                let data = res[1]
                console.log(data)

                if (statusCode === 200) {
                    console.log(data.answer)
                    chats.push({ message: message, side: 'RIGHT' })
                    chats.push({ message: data.answer, side: 'LEFT' })
                    chats.push({ message: data.reference, side: 'RLEFT' })
                    setChats([...chats])
                    setMessage('')
                    setLoading(false)
                }
            })
            .catch(error => {
                setLoading(false)
            })
    }
    return (
        <>
            {/* Commenting the following code for WOII-104. Divya. October 03 2023.. Code Start */}
            {/*<div className='chat-box'>*/}
            {/* Commenting end. Divya. October 03 2023.. */}
            {/* Added this code for  WOII-104. Divya. October 03 2023.. Code Start */}
            <div className='chat-box' style={{height : isMinimized ? '10px' : '500px'}}>
            {/* Divya. October 03 2023. Code End */}
                <div className='chat-box-header'>
                    <div className='chat-title text-center'>{props.title}</div>
                    <div className='mt-2 mr-3' onClick={() => props.onClose()}>
                        <img src={require('../assets/css/images/cross.png').default} alt="No image found" id="img_close" width="20" />
                    </div>
                    {/* Commenting the following code for WOII-104. Divya. October 13 2023.. Code Start */}
                    {/* Added this code for  WOII-104. Divya. October 03 2023.. Code Start */}
                    {/*<div className='mr-3' style={{color:'white',fontSize:'40px',width:'20px',height:'20px',backgroundColor:'#044661',border:'1px solid white',lineHeight:'0.3',borderRadius:'50%', display:'flex',JustifyContent:'center',AlignItems:'center',cursor:'pointer',marginTop:'12px'}} onClick={() => setMinimized(!isMinimized)}> - </div>*/}
                    {/* Divya. October 03 2023. Code End */}
                    {/* Commenting end. Divya. October 13 2023.. */}
                    {/* Added this code for  WOII-104. Divya. October 13 2023.. Code Start */} 
                    <div className='mr-3' style={{color:'white',width:'15px',height:'15px',backgroundColor:'#044661',border:'1px solid white',lineHeight:'0.3', display:'flex',JustifyContent:'center',AlignItems:'center',cursor:'pointer',marginTop:'15px',...(isMinimized ? plusStyle : minusStyle)}} onClick={() => setMinimized(!isMinimized)}> {isMinimized ? "":"-"}</div>
                    {/* Divya. October 13 2023. Code End */}
                </div>
                {/* Added this code for  WOII-104. Divya. October 03 2023.. Code Start */}
                {
                !isMinimized&&(
                //Divya. October 03 2023. Code End 
                <div className='msg-box'>
                    {
                        chats.map((res) => {
                            return (
                                <div>
                                    {
                                        res.side == 'LEFT' &&
                                        <div class="msg-container">
                                            <div class="arrow">
                                                <div class="outer"></div>
                                                <div class="inner"></div>
                                            </div>
                                            <div class="message-body">
                                            <b  style={messageStyle}>Answer:</b>
                                                <p>{res.message}</p>
                                            </div>
                                        </div>
                                    }
                                    {
                                        res.side == 'RLEFT' &&
                                        <div class="msg-container">
                                            <div class="arrow">
                                                <div class="outer"></div>
                                                <div class="inner"></div>
                                            </div>
                                            <div class="message-body">
                                            <b style={messageStyle}>Source:</b>
                                                <p>{res.message}</p>
                                            </div>
                                        </div>
                                    }
                                    {
                                        res.side == 'RIGHT' &&
                                        <div class="msg-container-right">
                                            <div class="arrow-right">
                                                <div class="outer-right"></div>
                                                <div class="inner-right"></div>
                                            </div>
                                            <div class="message-body-right">
                                                
                                                <b  style={messageStyle}>Question:</b>
                                                <p>{res.message}</p>
                                            </div>
                                        </div>
                                    }
                                </div>
                            )
                        })
                    }
                    {
                        chats.length == 0 &&
                        <div className='text-center'>No Messages Found!</div>
                    }
                </div>
                // Added this code for  WOII-104. Divya. October 03 2023.. Code Start 
                )}

                {
                !isMinimized &&(
                //Divya. October 03 2023. Code End 
                <div className='chat-bottom-view'>
                    <input className="form-control w-100 chat-input" value={message} onChange={(e) => setMessage(e.target.value)} />
                    <button className='chat-send-button' onClick={() => onSend()} disabled={isLoading}>
                        {isLoading && (
                            <img src={require('../assets/css/images/button_loader.gif').default} alt="loading..." style={{ marginRight: 5 }} />
                        )}Send</button>
                </div>
               // Added this code for  WOII-104. Divya. October 03 2023.. Code Start 
                )}
                {/* Divya. October 03 2023. Code End */}
            </div >
        </>
    )
}

export default Chat;                                                   