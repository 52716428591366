import React, { useEffect, useState } from "react";
import Leftnavbar from "../../../../components/Leftnavbar";
import { NavLink } from "react-router-dom";
import { APIMethod, FileUploadMethod } from "../../../../API/APIClient";
import Loader from "../../../../components/Loader";
import ModalPopup from "../../../../components/ModalPopUp";
import Pagination from "../../../../components/Pagination";
import { useToasts } from "react-toast-notifications";
import { useHistory } from "react-router";
import moment from "moment";
import NoData from "../../../../components/NoDataFound";
import SearchNoDataFound from "../../../../components/SearchNoDataFound";
import ReactModal from "react-modal-resizable-draggable";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const SetupQa = (props) => {
  const [getListData, setGetListData] = useState([]);
  const [RFxCategoryData, setRFxCategoryData] = useState([]);
  const [loading, setLoading] = useState(false);
  // Edit
  const [edit, setEdit] = useState("");
  const [id, setId] = useState("");
  // Delete
  const [deleteId, setDeleteId] = useState("");
  const [showModalPopup, setShowModalPopup] = useState(false);
  // page
  const [pageCount, setPageCount] = useState(
    localStorage.getItem("QnA_PageCount") === undefined
      ? ""
      : localStorage.getItem("QnA_PageCount")
  );
  const [currentPage, setCurrentPage] = useState(
    Number(localStorage.getItem("QnA_CurrentPage")) === 0
      ? 1
      : localStorage.getItem("QnA_CurrentPage")
  );
  {
    /* Added this code for  WOII-120. Divya. November 07 2023.. Code Start */
  }
  const [rowsPerPage, setRowsPerPage] = useState(10);
  {
    /* Divya. November 07 2023. Code End */
  }
  // Toast
  const { addToast } = useToasts();
  const history = useHistory();
  const [adding, setAdding] = useState();
  const [editing, setEditing] = useState();
  const [deleting, setDeleting] = useState();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [formFields, setFormFields] = useState({
    // Commenting the following code for WOII-185. Divya. March 14 2024.. Code Start
    //fromDate: undefined, toDate: undefined, question: '', answer: '', rfxCategory: ''
    //Commenting end. Divya  March 14 2024..
    // Added this code for  WOII-185. Divya. March 14 2024.. Code Start
    fromDate: undefined,
    toDate: undefined,
    question: "",
    answer: "",
    rfxCategory: "",
    status: "ACTIVE",
    //Divya. March 14 2024. Code End
  });
  {
    /*Added this code for  WOII-118. Divya. December 23 2023.. Code Start */
  }
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [buttonLoader, setButtonLoader] = useState(false);
  const [modalFlag, setModalFlag] = useState(false);
  const [file, setFile] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [excel, setExcel] = useState();
  const [startDate, setStartDate] = useState(new Date());
  const [word, setWord] = useState();
  const [rfxCategoryId, setRfxCategoryId] = useState("");
  const [rfxBool, setrfxBool] = useState(false);
  const [excelBool, setExcelBool] = useState(false);
  const [wordBool, setWordBool] = useState(false);

  {
    /* Divya. December 23 2023. Code End*/
  }
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    //Commenting the following code for WOII-120. Divya. November 07 2023.. Code Start
    //getQAList(1)
    //Commenting end. Divya. November 07 2023..
    //Added this code for  WOII-120. Divya. November 07 2023.. Code Start
    getQAList(Number(currentPage));
    {
      /* Divya. November 07 2023. Code End */
    }
    getRfxCategoryList();
    var data = JSON.parse(localStorage.getItem("user_Role"));
    checkPermission(data);
    //Commenting the following code for WOII-120. Divya. November 07 2023.. Code Start
    // }, [])
    //Commenting end. Divya. November 07 2023..
    //Added this code for  WOII-120. Divya. November 07 2023.. Code Start
  }, [rowsPerPage, Number(currentPage)]);
  {
    /* Divya. November 07 2023. Code End */
  }

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  {
    /*Added this code for  WOII-118. Divya. December 23 2023.. Code Start*/
  }
  const handleCloseWord = () => {
    setIsModalOpen(false);
  };
  const handleChange1 = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    setrfxBool(true);
    setRfxCategoryId(value);
  };
  const handleCloseExcel = () => {
    setIsOpen(false);
  };

  const handleWordSelect = (event) => {
    setWord(event.target.files[0]);
    setWordBool(true);
  };

  const handleExcelSelect = (event) => {
    setExcel(event.target.files[0]);
    setExcelBool(true);
  };

  {
    /*Added this code for  WOII-118. Bhovan. December 27 2023.. Code Start*/
  }
  const save = async (file) => {
    setIsOpen(false);
    try {
      const formData = new FormData();
      formData.append("file", excel);

      const apifetcherObj = await FileUploadMethod(
        "/qna/file",
        formData,
        "POST"
      );
      //   console.log(apifetcherObj.text())
      const [responseStatus, responseData] = await Promise.all([
        apifetcherObj.status,
        apifetcherObj.text(),
      ]);

      //   console.log(responseData, responseStatus);

      if (responseStatus === 200) {
        // x=responseData
        var error_file = responseData.split(".");
        console.log(error_file, error_file[0], error_file[1]);

        addToast(error_file[0], { appearance: "success", autoDismiss: true });
        setIsOpen(false);
        if (error_file[1] != "[]") {
          addToast("Error occured in these index " + error_file[1], {
            appearance: "warning",
            autoDismiss: true,
          });
        }
        if (error_file[2] != "[]") {
          addToast("Already existings questions in excel" + error_file[2], {
            appearance: "warning",
            autoDismiss: true,
          });
        }

        // return responseData.result;
        getQAList(Number(currentPage));
      } else {
        addToast(responseData, { appearance: "error", autoDismiss: true });
        // Handle the error or set appropriate state here
        setIsOpen(false);
        setButtonLoader(false);
      }
    } catch (error) {
      setIsOpen(false);
      console.log(error);
      addToast("Error while uploading the file", {
        appearance: "error",
        autoDismiss: true,
      });
      // Handle the error or set appropriate state here
      setButtonLoader(false);
      //   console.error('Error during file upload:', error);
    }
  };

  {
    /* Bhovan. December 7 2023. Code End*/
  }
  {
    /*Added this code for  WOII-118. Bhovan. February 7 2024.. Code Start*/
  }
  const saveWord = async (file) => {
    setIsModalOpen(false);
    let newdate = moment(startDate).format("YYYY-MM-DD");
    try {
      const formData = new FormData();
      formData.append("file", word);
      formData.append("date", newdate);
      formData.append("rfxCategoryId", rfxCategoryId ? rfxCategoryId : null);
      const apifetcherObj = await FileUploadMethod(
        "/qna/word",
        formData,
        "POST"
      );
      //   console.log(apifetcherObj.text())
      const [responseStatus, responseData] = await Promise.all([
        apifetcherObj.status,
        apifetcherObj.text(),
      ]);

      //   console.log(responseData, responseStatus);

      if (responseStatus === 200) {
        var error_file = responseData.split(".");
        setIsModalOpen(false);

        addToast("Q&A imported from word success", {
          appearance: "success",
          autoDismiss: true,
        });

        if (error_file[1] != "[]") {
          addToast("Already existings questions in excel" + error_file[1], {
            appearance: "warning",
            autoDismiss: true,
          });
        }

        getQAList(Number(currentPage));
      } else {
        addToast(responseData, { appearance: "error", autoDismiss: true });
        setIsOpen(false);
        setButtonLoader(false);
      }
    } catch (error) {
      setIsOpen(false);
      console.log(error);
      addToast("Error while uploading the file", {
        appearance: "error",
        autoDismiss: true,
      });
      // Handle the error or set appropriate state here
      setButtonLoader(false);
      //   console.error('Error during file upload:', error);
    }
  };

  {
    /* Bhovan. February 7  2024. Code End*/
  }

  {
    /* Commenting the following code for WOII-118. Bhovan. December 27 2023. Comment Code Start*/
  }
  // const save = async(file) => {
  //     try {
  //     const formData = new FormData();
  //     formData.append('file', excel);

  //     const apifetcherObj = FileUploadMethod(`/qna/file`, formData, 'POST');
  //         apifetcherObj
  //             .then(response => { return Promise.all([response.status, response.json()]) })
  //             .then(res => {
  //                 let statusCode = res[0]
  //                 let data = res[1]
  //                 console.log(res,statusCode)
  //                 if (statusCode === 200) {
  //                     addToast(data.message, { appearance: 'success', autoDismiss: true });
  //                     resolve(data.result);
  //                 } else {
  //                     addToast(data.message, { appearance: 'error', autoDismiss: true });
  //                     setButtonLoader(false)
  //                 }
  //             })
  //         }
  //             catch(error){
  //                 setButtonLoader(false)
  //             }
  //         }
  // }
  {
    /* Divya. December 23 2023. Code End*/
  }
  {
    /* Bhovan. December 27 2023. Comment End*/
  }
  const checkPermission = (data) => {
    const resObject = data.find(
      (item) => item.name === "Content Library Setup"
    );
    const valObject = resObject.access.find((item) => item.name === "Q&As");
    setAdding(valObject.is_create);
    setEditing(valObject.is_update);
    setDeleting(valObject.is_delete);
  };

  const getQAList = (page, key, type) => {
    setLoading(true);
    const new_key = key === undefined ? "" : key;
    global.new_key = key === undefined ? "" : key;
    var filter_start_date = "";
    if (formFields.fromDate) {
      filter_start_date =
        moment(formFields.fromDate).format("YYYY-MM-DD") ==
        moment(formFields.toDate).format("YYYY-MM-DD")
          ? ""
          : moment(formFields.fromDate).format("YYYY-MM-DD");
    }
    const filter_end_date =
      formFields.toDate === undefined
        ? ""
        : moment(formFields.toDate).format("YYYY-MM-DD");
    var method = "GET";
    //Commenting the following code for WOII-120. Divya. November 07 2023.. Code Start
    //const apifetcherObj = APIMethod(`/qna/list/${page}?question=${formFields.question}&answer=${formFields.answer}&from_date=${filter_start_date}&to_date=${filter_end_date}&rfx_category_id=${formFields.rfxCategory}&question_answer=` + new_key, null, method)
    //Commenting end. Divya. November 07 2023..
    //Added this code for  WOII-120. Divya. November 07 2023.. Code Start
    // Commenting the following code for WOII-185. Divya. March 14 2024.. Code Start
    //const apifetcherObj = APIMethod(`/qna/list/${page}?question=${formFields.question}&answer=${formFields.answer}&from_date=${filter_start_date}&to_date=${filter_end_date}&rfx_category_id=${formFields.rfxCategory}&is_approvals=${false}&limit=${rowsPerPage}&question_answer=` + new_key, null, method)
    {
      /* Divya. November 07 2023. Code End */
    }
    //Commenting end. Divya  March 14 2024..
    // Added this code for  WOII-185. Divya. March 14 2024.. Code Start
    const apifetcherObj = APIMethod(
      `/qna/list/${page}?question=${formFields.question}&answer=${
        formFields.answer
      }&from_date=${filter_start_date}&to_date=${filter_end_date}&rfx_category_id=${
        formFields.rfxCategory
      }&is_approvals=${false}&limit=${rowsPerPage}&status=${
        formFields.status
      }&question_answer=` + new_key,
      null,
      method
    );
    //Divya. March 14 2024. Code End
    apifetcherObj
      .then((response) => {
        return Promise.all([response.status, response.json()]);
      })
      .then((res) => {
        let statusCode = res[0];
        let data = res[1];

        if (statusCode === 200) {
          setGetListData(data.list);
          setPageCount(data.count);
          localStorage.setItem("QnA_PageCount", data.count);
          if (
            Math.ceil(data.count / 10) < localStorage.getItem("QnA_CurrentPage")
          ) {
            localStorage.removeItem("QnA_CurrentPage");
            localStorage.removeItem("QnA_PageCount");
            window.location.reload();
          } else {
            setCurrentPage(page);
            localStorage.setItem("QnA_CurrentPage", page);
            setLoading(false);
          }
        } else {
          addToast(data.message, { appearance: "error", autoDismiss: true });
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const getRfxCategoryList = () => {
    setLoading(true);
    var method = "GET";
    const apifetcherObj = APIMethod(`/rfx-categories/all/list`, null, method);
    apifetcherObj
      .then((response) => {
        return Promise.all([response.status, response.json()]);
      })
      .then((res) => {
        let statusCode = res[0];
        let data = res[1];

        if (statusCode === 200) {
          setRFxCategoryData(data);
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  // Added this code for  WOII-185. Divya. March 14 2024.. Code Start
  const enableRow = (id) => {
    var method = "PUT";
    const apifetcherObj = APIMethod(`/qna/${id}/enable`, null, method);
    apifetcherObj
      .then((response) => {
        return Promise.all([response.status, response.json()]);
      })
      .then((res) => {
        let statusCode = res[0];
        let data = res[1];

        if (statusCode === 200) {
          getQAList(Number(currentPage));
          isShowPopup(false);
        }
      })
      .catch((error) => {
        console.error("Error enabling Qna:", error);
      });
  };

  const deleteRow = (id) => {
    var method = "DELETE";
    const apifetcherObj = APIMethod(`/qna/${id}/delete`, null, method);
    apifetcherObj
      .then((response) => {
        return Promise.all([response.status, response.json()]);
      })
      .then((res) => {
        let statusCode = res[0];
        let data = res[1];

        if (statusCode === 200) {
          getQAList(Number(currentPage));
          isShowPopup(false);
        }
      })
      .catch((error) => {
        console.error("Error in deleting Qna:", error);
      });
  };
  //Divya. March 14 2024. Code End

  const nextPage = (pageNumber) => {
    //Commenting the following code for WOII-120. Divya. November 07 2023.. Code Start
    //getQAList(pageNumber)
    //Commenting end. Divya. November 07 2023..
    //Added this code for  WOII-120. Divya. November 07 2023.. Code Start
    setCurrentPage(pageNumber);
    localStorage.setItem("QnA_CurrentPage", pageNumber);
    {
      /* Divya. November 07 2023. Code End */
    }
  };

  {
    /* Added this code for  WOII-120. Divya. November 02 2023.. Code Start */
  }
  const handleRowsPerPageChange = (selectedValue) => {
    setRowsPerPage(selectedValue);
  };
  {
    /* Divya. November 02 2023. Code End */
  }

  const _onDelete = (id) => {
    setDeleteId(id);
    isShowPopup(true);
  };

  const isShowPopup = (status) => {
    setShowModalPopup(status);
  };

  const Search = (key) => {
    getQAList(1, key);
  };

  const _onEdit = (val) => {
    history.push({
      pathname: "/edit-q-a/:id",
      state: { details: val, page: "qna" },
    });
  };

  const getData = () => {
    getQAList(1);
  };

  const handleChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    const formFieldsObject = {
      ...formFields,
      [name]: value,
    };
    setFormFields(formFieldsObject);
  };

  const _onResetFilter = (type) => {
    if (type === "QUESTION") {
      formFields.question = "";
    } else if (type === "ANSWER") {
      formFields.answer = "";
    } else if (type === "RFX_CATEGORY") {
      formFields.rfxCategory = "";
    } else if (type === "FROM_DATE") {
      formFields.fromDate = undefined;
    } else if (type === "TO_DATE") {
      formFields.toDate = undefined;
    }
    // Added this code for  WOII-185. Divya. March 14 2024.. Code Start
    else if (type === "STATUS") {
      formFields.status = "";
    }
    //Divya. March 14 2024. Code End
    else {
      formFields.question = "";
      formFields.answer = "";
      formFields.rfxCategory = "";
      // Added this code for  WOII-185. Divya. March 14 2024.. Code Start
      formFields.status = "";
      //Divya. March 14 2024. Code End
      formFields.fromDate = undefined;
      formFields.toDate = undefined;
      handleClose();
    }
    getQAList(1, "", "FILTER");
  };

  const _onFilter = () => {
    getQAList(1, "", "FILTER");
    handleClose();
  };

  const setFromDate = (date) => {
    const formFieldsObject = {
      ...formFields,
      fromDate: date,
    };
    setFormFields(formFieldsObject);
  };

  const dateValidation = (date) => {
    if (formFields.fromDate) {
      const formFieldsObject = {
        ...formFields,
        toDate: date,
      };
      setFormFields(formFieldsObject);
    } else {
      addToast("Please select RFx closing from date first", {
        autoDismissTimeout: TOAST_TIMEOUT,
        appearance: "warning",
        autoDismiss: true,
      });
    }
  };

  function truncateText(text, maxLength = 200, datatype = 1) {
    const tempElement = document.createElement("div");
    tempElement.innerHTML = text;

    // Get the decoded text content
    var decodedText = tempElement.textContent || tempElement.innerText;
    decodedText = decodedText.replace(/\s+/g, " ");
    if (datatype === 0) {
      return decodedText;
    }
    // Truncate the replaced text if needed
    if (decodedText.length <= maxLength) {
      return decodedText;
    } else {
      return decodedText.substring(0, maxLength) + "…";
    }
  }

  return (
    <>
      <div className="page-wrapper">
        <div className="page-content">
          <div className="row mb-3 ">
            <div className="col-lg-5 col-md-12 d-md-flex mb-md-0 mb-2 mb-lg-0 mb-md-3">
              <h2 className="page-title mb-md-0">Q&As</h2>
            </div>
            <div className="col-lg-5 col-md-12 d-md-flex align-items-center justify-content-center">
              <div className="input-group page-cont-search mr-md-3 mb-md-0 mb-2">
                {/*Commenting the following code for WOII-118. Divya. December 23 2023.. Code Start*/}
                {/*<input type="search" className="form-control" placeholder="Search by question and answer" onChange={event => Search(event.target.value)} />
                                <span className="input-group-text text-secondary"><i className="icofont-search-1"></i></span>*/}
                {/*Commenting end. Divya. december 23 2023..*/}
                {/*Added this code for  WOII-118. Divya. December 23 2023.. Code Start*/}
                <input
                  type="search"
                  className="form-control"
                  style={{ width: "200px" }}
                  placeholder="Search by question and answer"
                  onChange={(event) => Search(event.target.value)}
                />
                <div
                  className="input-group-prepend"
                  style={{ position: "relative", zIndex: "2" }}
                >
                  <span
                    className="input-group-text text-secondary"
                    style={{ marginLeft: "-40px", zIndex: "2" }}
                  >
                    <i className="icofont-search-1"></i>
                  </span>
                </div>
                {/* Divya. December 23 2023. Code End*/}
              </div>
              <button
                type="button"
                className="btn btn-brand-1 btn-h-40 mr-3"
                onClick={() => setShow(true)}
              >
                <i className="icofont-filter mr-2"></i> Filter
              </button>
              {
                adding === true ? (
                  //Commenting the following code for WOII-118. Divya. December 23 2023.. Code Start
                  //<NavLink to="/add-q-a" className="btn btn-brand-1 bg-success px-4">+ Add</NavLink> : null
                  //Commenting end. Divya. december 23 2023..
                  //Added this code for  WOII-118. Divya. December 23 2023.. Code Start
                  <NavLink
                    to="/add-q-a"
                    className="btn btn-brand-1 bg-success px-4 mr-3"
                  >
                    + Add
                  </NavLink>
                ) : null
                //Divya. December 23 2023. Code End
              }
              {/*Added this code for  WOII-118. Divya. December 23 2023.. Code Start*/}
              <div className="container">
                <div className="row">
                  <div className="col" style={{ width: "200px" }}>
                    <button
                      type="button"
                      className="btn btn-brand-1 bg-success"
                      style={{ lineHeight: "1" }}
                      onClick={() => setIsModalOpen(true)}
                    >
                      Import From Word File
                    </button>
                  </div>
                </div>
              </div>
              <div className="container">
                <div className="row">
                  <div className="col" style={{ width: "200px" }}>
                    <button
                      type="button"
                      className="btn btn-brand-1 bg-success"
                      style={{ lineHeight: "1" }}
                      onClick={() => setIsOpen(true)}
                    >
                      Import From Excel File
                    </button>
                  </div>
                </div>
              </div>
              {/* Divya. December 23 2023. Code End*/}
            </div>
          </div>

          <div className="mb-2 filter-by">
            {formFields.question ? (
              <div className="badge">
                <span className="lable">Question</span>
                <span className="value">{formFields.question}</span>
                <i
                  className="icofont-close"
                  onClick={() => _onResetFilter("QUESTION")}
                ></i>
              </div>
            ) : null}
            {formFields.answer ? (
              <div className="badge">
                <span className="lable">Answer</span>
                <span className="value">{formFields.answer}</span>
                <i
                  className="icofont-close"
                  onClick={() => _onResetFilter("ANSWER")}
                ></i>
              </div>
            ) : null}
            {/* Added this code for  WOII-185. Divya. March 14 2024.. Code Start */}
            {formFields.status ? (
              <div className="badge">
                <span className="lable">Status</span>
                <span className="value">
                  {formFields.status == "ACTIVE"
                    ? "Active"
                    : formFields.status == "DELETED"
                    ? "Archived"
                    : formFields.status}
                </span>
                <i
                  className="icofont-close"
                  onClick={() => _onResetFilter("STATUS")}
                ></i>
              </div>
            ) : null}
            {/* Divya. March 14 2024. Code End  */}
            {formFields.rfxCategory ? (
              <div className="badge">
                <span className="lable">RFx Category</span>
                {RFxCategoryData.map((res, index) => {
                  return (
                    <span key={index} className="value">
                      {formFields.category == res.id ? res.name : null}
                    </span>
                  );
                })}
                <i
                  className="icofont-close"
                  onClick={() => _onResetFilter("RFX_CATEGORY")}
                ></i>
              </div>
            ) : null}
            {formFields.fromDate ? (
              <div className="badge">
                <span className="lable">Q&As From Date</span>
                <span className="value">
                  {moment(formFields.fromDate).format("MM-DD-YYYY")}
                </span>
                <i
                  className="icofont-close"
                  onClick={() => _onResetFilter("FROM_DATE")}
                ></i>
              </div>
            ) : null}
            {formFields.toDate ? (
              <div className="badge">
                <span className="lable">Q&As To Date</span>
                <span className="value">
                  {moment(formFields.toDate).format("MM-DD-YYYY")}
                </span>
                <i
                  className="icofont-close"
                  onClick={() => _onResetFilter("TO_DATE")}
                ></i>
              </div>
            ) : null}
          </div>
          <div
            style={{ 
              height: "70vh",
              overflowY: "auto",
              marginBottom: "20px",
              resize: "vertical",
              overflow: "auto",
              maxHeight: "110vh"
             }}
          >
            <table className="table table-style-1 table-responsive-lg mb-4 table reports">
              <thead className="sticky-top" style={{ zIndex: 10 }}>
                <tr>
                  <th scope="col">QAID</th>
                  <th scope="col">Question/Topic</th>
                  <th scope="col">Answer</th>
                  <th scope="col">Status</th>
                  <th scope="col">RFx Category</th>
                  <th scope="col">Creation Date</th>
                  <th scope="col" className="text-center" width="80">
                    {editing === true || deleting === true ? "Action" : null}
                  </th>
                </tr>
              </thead>
              <ModalPopup
                showModalPopup={showModalPopup}
                onPopupClose={isShowPopup}
                id={deleteId}
                endPoint={"/qna/"}
                updateList={getData}
              ></ModalPopup>
              <tbody>
                {loading === false
                  ? getListData.map((res) => {
                      var qna_status = "";
                      if (res.q_status == "PUSH_BACK") {
                        qna_status = "Q&A is Pushedback";
                      } else if (res.q_status == "Q&A_CREATED") {
                        qna_status = "Q&A is Created";
                      } else if (res.q_status == "PENDING_APPROVAL") {
                        qna_status = "Q&A is Sent for Approval";
                      } else if (res.q_status == "APPROVED") {
                        qna_status = "Q&A is Approved";
                      }
                      {
                        /* Added this code for  WOII-185. Divya. March 14 2024.. Code Start */
                      }
                      const isRowArchived = res.status === "DELETED";
                      {
                        /* Divya. March 14 2024. Code End  */
                      }
                      return (
                        // Commenting the following code for WOII-185. Divya. March 14 2024.. Code Start
                        //<tr>
                        //Commenting end. Divya  March 14 2024..
                        // Added this code for  WOII-185. Divya. March 14 2024.. Code Start
                        <tr
                          key={res.id}
                          className={isRowArchived ? "archived" : ""}
                        >
                          {/* Divya. March 14 2024. Code End */}
                          <td>QA{res.id}</td>
                          <td title={res.question}>
                            {res.question.replace(/(.{50})..+/, "$1…")}
                          </td>
                          <td
                            title={res.answer
                              .replace(/<[^>]+>/g, "")
                              .replace(/&rsquo;/g, "’")
                              .replace(/&nbsp;/g, " ")
                              .replace(/&ndash;/g, "—")
                              .replace(/&lt;/g, "<")
                              .replace(/&gt;/g, ">")
                              .replace(/&amp;/g, "&")
                              .replace(/&quot;/g, '"')
                              .replace(/&apos;/g, "'")
                              .replace(/&copy;/g, "©")
                              .replace(/&reg;/g, "®")
                              .replace(/&trade;/g, "™")
                              .replace(/&euro;/g, "€")
                              .replace(/&pound;/g, "£")
                              .replace(/&yen;/g, "¥")
                              .replace(/&cent;/g, "¢")
                              .replace(/&times;/g, "×")
                              .replace(/&divide;/g, "÷")
                              .replace(/&plusmn;/g, "±")
                              .replace(/&frac14;/g, "¼")
                              .replace(/&frac12;/g, "½")
                              .replace(/&frac34;/g, "¾")}
                          >
                            {truncateText(res.answer)}
                          </td>
                          <td>{qna_status}</td>
                          <td>{res.rfx_category_name}</td>
                          <td>
                            {res.start_date
                              ? moment(res.start_date).format("MM-DD-YYYY")
                              : null}
                          </td>
                          <td className="text-center">
                            {/* Commenting the following code for WOII-185. Divya. March 14 2024.. Code Start  */}
                            {/* {
                                                        editing === true && res.q_status!='APPROVED' ?(
                                                            <button type="button" className="btn btn-action edit mr-1" disabled={res.q_status=='PENDING_APPROVAL' || res.q_status=='PUSHED_BACK'} onClick={() => _onEdit(res)}><i className="icofont-pencil-alt-2"></i></button>
                                                            ):(
                                                                <button type="button" className="btn btn-action edit mr-1" disabled={res.q_status=='PENDING_APPROVAL' || res.q_status=='PUSHED_BACK'} onClick={() => _onEdit(res)}><i className="icofont-eye-alt"></i></button>
                                                            )
                                                    }
                                                    {
                                                        deleting === true ?
                                                            <button type="button" className="btn btn-action delete" onClick={() => _onDelete(res.id)}><i className="icofont-archive"></i></button> : null
                                                    } */}
                            {/* Commenting end. Divya  March 14 2024..
                                                     Added this code for  WOII-185. Divya. March 14 2024.. Code Start */}
                            {isRowArchived ? (
                              <>
                                <button
                                  type="button"
                                  className="btn btn-action enable"
                                  onClick={() => enableRow(res.id)}
                                >
                                  <i
                                    className="icofont-check"
                                    style={{ fontSize: "18px" }}
                                  ></i>
                                </button>
                                <button
                                  type="button"
                                  className="btn btn-action delete ml-1"
                                  onClick={() => deleteRow(res.id)}
                                >
                                  <i className="icofont-archive"></i>
                                </button>
                              </>
                            ) : (
                              <>
                                {/* {editing === true && res.q_status !== 'APPROVED' ? (
                                                                    // <button type="button" className="btn btn-action edit mr-1" disabled={res.q_status === 'PENDING_APPROVAL' || res.q_status === 'PUSHED_BACK'} onClick={() => _onEdit(res)}><i className="icofont-pencil-alt-2"></i></button>
                                                                    //     ) : (
                                                                    //         <button type="button" className="btn btn-action edit mr-1" disabled={res.q_status === 'PENDING_APPROVAL' || res.q_status === 'PUSHED_BACK'} onClick={() => _onEdit(res)}><i className="icofont-eye-alt"></i></button>
                                                                    //         )
                                                                    */}
                                {editing === true ? (
                                  <button
                                    type="button"
                                    className="btn btn-action edit mr-1"
                                    onClick={() => _onEdit(res)}
                                  >
                                    <i className="icofont-pencil-alt-2"></i>
                                  </button>
                                ) : null}
                                {deleting === true ? (
                                  <button
                                    type="button"
                                    className="btn btn-action delete"
                                    onClick={() => _onDelete(res.id)}
                                  >
                                    <i className="icofont-archive"></i>
                                  </button>
                                ) : null}
                              </>
                            )}
                            {/* Divya. March 14 2024. Code End */}
                          </td>
                        </tr>
                      );
                    })
                  : null}
              </tbody>
            </table>
          </div>
          {getListData.length === 0 && loading === false && global.new_key ? (
            <SearchNoDataFound message={"No Q&A Entrie Found"} />
          ) : getListData.length === 0 && loading === false ? (
            <NoData />
          ) : null}
          {<Loader isLoading={loading} />}
          <div className="text-md-right">
            <nav aria-label="Page navigation">
              <Pagination
                pages={pageCount}
                nextPage={nextPage}
                currentPage={Number(currentPage)}
                pageCount={pageCount}
                //Added this code for  WOII-120. Divya. November 07 2023.. Code Start
                rowsPerPage={handleRowsPerPageChange}
                // Divya. November 07 2023. Code End
              />
            </nav>
          </div>
        </div>
      </div>

      {/* Filter Modal Box */}
      <ReactModal
        initWidth={550}
        initHeight={"auto"}
        top={137.5}
        className={"contained-modal-title-vcenter"}
        onRequestClose={handleClose}
        isOpen={show}
        disableResize={true}
        disableKeystroke={true}
      >
        <div className="modal-header">
          <h5 className="modal-title" id="">
            Filter Q&As
          </h5>
          <button type="button" className="close" onClick={handleClose}>
            <i className="icofont-close"></i>
          </button>
        </div>
        <div className="modal-body">
          <div className="row form-style">
            <div className="col-md-6">
              <div className="form-group">
                <label>Question/Topic</label>
                <input
                  type="text"
                  className="form-control"
                  onChange={handleChange}
                  name="question"
                  value={formFields["question"]}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label>Answer</label>
                <input
                  type="text"
                  className="form-control"
                  onChange={handleChange}
                  name="answer"
                  value={formFields["answer"]}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label>Category</label>
                <select
                  id="disabledSelect"
                  className="form-control custom-select"
                  onChange={handleChange}
                  name="rfxCategory"
                  value={formFields["rfxCategory"]}
                >
                  <option value="" disabled>
                    Select your option
                  </option>
                  {RFxCategoryData.map((res, index) => {
                    return (
                      <option key={index} value={res.id}>
                        {res.name}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>

            <div className="col-md-6">
              <div className="form-group">
                <label>Q&As From Date</label>
                <DatePicker
                  selected={formFields.fromDate}
                  onChange={(date) => setFromDate(date)}
                  className="form-control"
                  placeholder="select Date"
                  dateFormat="MM-dd-yyyy"
                  scrollableYearDropdown
                  showYearDropdown
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label>Q&As To Date </label>
                <DatePicker
                  selected={formFields.toDate}
                  onChange={(date) => dateValidation(date)}
                  className="form-control"
                  placeholder="select Date"
                  dateFormat="MM-dd-yyyy"
                  minDate={formFields.fromDate}
                  scrollableYearDropdown
                  showYearDropdown
                />
              </div>
            </div>
            {/* Added this code for  WOII-185. Divya. March 14 2024.. Code Start */}
            <div className="col-md-6">
              <div className="form-group">
                <label>Status</label>
                <select
                  id="disabledSelect"
                  className="form-control custom-select"
                  onChange={handleChange}
                  name="status"
                  value={formFields["status"]}
                >
                  <option value="" disabled>
                    Select your option
                  </option>
                  <option value="ACTIVE">Active</option>
                  <option value="DELETED">Archived</option>
                </select>
              </div>
            </div>
            {/* Divya. March 14 2024. Code End */}
          </div>
        </div>
        <div className="modal-footer ">
          <button
            type="button"
            className="btn btn-lg btn-brand-1 bg-danger"
            onClick={handleClose}
          >
            Cancel
          </button>
          <button
            className="btn btn-lg btn-brand-1"
            onClick={() => _onResetFilter()}
          >
            Reset
          </button>
          <button
            type="button"
            className="btn btn-lg btn-brand-1 bg-success"
            onClick={() => _onFilter()}
          >
            Search
          </button>
        </div>
      </ReactModal>

      {/*Added this code for  WOII-118. Divya. December 23 2023.. Code Start*/}
      <ReactModal
        isOpen={isModalOpen}
        onRequestClose={handleCloseWord}
        className="contained-modal-title-vcenter"
        initWidth={550}
        initHeight={"auto"}
        top={137.5}
      >
        <div className="modal-header">
          <h5 className="modal-title">Select Word Document</h5>
          <button type="button" className="close" onClick={handleCloseWord}>
            <i className="icofont-close"></i>
          </button>
        </div>
        <div className="modal-body">
          <div className="form-group">
            <label>Upload Word Document</label>
            <div className="custom-file">
              <input
                type="file"
                className="custom-file-input"
                id="inputGroupFile02"
                accept=".docx"
                onChange={handleWordSelect}
              />
              <label
                className="custom-file-label"
                htmlFor="inputGroupFile02"
                aria-describedby="inputGroupFileAddon02"
              >
                {word === undefined ? "Choose File" : word.name}
              </label>
              <span className="text-danger">
                {!wordBool ? "Please Select file" : null}
              </span>
            </div>
          </div>
          <div className="form-group">
            <label>Creation Date</label>
            <DatePicker
              selected={startDate}
              disabled={false}
              onChange={(date) => setStartDate(date)}
              className="form-control"
              placeholder="select Date"
              dateFormat="MM-dd-yyyy"
              scrollableYearDropdown
              showYearDropdown
            />
          </div>
          <div className="form-group">
            <label>RFx Category</label>
            <select
              id=""
              disabled={false}
              className="form-control custom-select"
              value={rfxCategoryId}
              name="RfxCategoryId"
              onChange={handleChange1}
            >
              <option value="" disabled selected>
                Select your option
              </option>
              {RFxCategoryData.map((res) => {
                return <option value={res.id}>{res.name}</option>;
              })}
            </select>
            <span className="text-danger">
              {rfxCategoryId === "" ? "Please Enter RFx Category Id" : null}
            </span>
          </div>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-secondary"
            onClick={handleCloseWord}
          >
            Cancel
          </button>
          <button
            type="button"
            className="btn btn-lg btn-brand-1 bg-success"
            onClick={saveWord}
            disabled={!(wordBool && rfxBool)}
          >
            {buttonLoader && (
              <img
                src={
                  require("../../../../assets/css/images/button_loader.gif")
                    .default
                }
                alt="loading..."
                style={{ marginLeft: 5 }}
              />
            )}
            <span>Save Word</span>
          </button>
        </div>
      </ReactModal>
      <ReactModal
        isOpen={isOpen}
        onRequestClose={handleCloseExcel}
        className="contained-modal-title-vcenter"
        initWidth={550}
        initHeight={"auto"}
        top={137.5}
      >
        <div className="modal-header">
          <h5 className="modal-title">Select Excel Document</h5>
          <button type="button" className="close" onClick={handleCloseExcel}>
            <i className="icofont-close"></i>
          </button>
        </div>
        <div className="modal-body">
          <div className="form-group">
            <label>Upload Excel Document</label>
            <div className="custom-file">
              <input
                type="file"
                className="custom-file-input"
                id="inputGroupFile02"
                accept=".xlsx"
                onChange={handleExcelSelect}
              />
              <label
                className="custom-file-label"
                htmlFor="inputGroupFile02"
                aria-describedby="inputGroupFileAddon02"
              >
                {excel === undefined ? "Choose File" : excel.name}
              </label>
              <span className="text-danger">
                {!excelBool ? "Please Select file" : null}
              </span>
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-secondary"
            onClick={handleCloseExcel}
          >
            Cancel
          </button>
          <button
            type="button"
            className="btn btn-lg btn-brand-1 bg-success"
            onClick={() => save()}
            disabled={!excelBool}
          >
            {buttonLoader && (
              <img
                src={
                  require("../../../../assets/css/images/button_loader.gif")
                    .default
                }
                alt="loading..."
                style={{ marginLeft: 5 }}
              />
            )}
            <span>Save</span>
          </button>
        </div>
      </ReactModal>
      {/* Divya. December 23 2023. Code End*/}
    </>
  );
};
export default SetupQa;
