import React, { useEffect, useState, Fragment, useRef, version } from "react";
import Form from 'react-bootstrap/Form';
import { Tab, Modal } from 'react-bootstrap';
import { useLocation } from "react-router-dom";
import Loader from '../components/Loader';
import DatePicker from "react-datepicker";
import { useToasts } from 'react-toast-notifications';
import { APIMethod, FileUploadMethod, TOAST_TIMEOUT } from "../API/APIClient";
import moment from "moment";
import ReactModal from 'react-modal-resizable-draggable';
import PhoneInput from 'react-phone-number-input';
import Toggle from 'react-toggle';
import { Editor } from '@tinymce/tinymce-react';
import { checkEmptyString } from "../utility/utility";
import DeleteModal from "./DeleteModal";
import ModalPopup from "./ModalPopUp";
import { CKEditor } from 'ckeditor4-react';

let Regex = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/;

const RfxData = (props) => {
    const descriptionRef = useRef(null);
    const [loading, setLoading] = useState(false);
    const [buttonLoader, setButtonLoader] = useState(false);
    const location = useLocation();
    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const [businessUnitData, setBusinessUnitData] = useState([]);
    const [RFxStatusData, setRFxStatusData] = useState([]);
    const [RFxCategoryData, setRFxCategoryData] = useState([]);
    const [RFxTypeData, setRFxTypeData] = useState([]);
    const [addDocuments, setAddDocuments] = useState([]);
    const [documentList, setDocumentList] = useState([]);
    const [countryData, setCountryData] = useState([]);
    const [stateData, setStateData] = useState([]);
    const [file, setFile] = useState();
    const [publishedDate, setPublishedDate] = useState('');
    const [dueDate, setDueDate] = useState('');
    const [closingDate, setClosingDate] = useState('');
    const [editIndex, setEditIndex] = useState(-1);
    const [showDeleteModal, setDeleteModal] = useState(false);
    const [deleteIndex, setDeleteIndex] = useState('');
    const [deleteId, setDeleteId] = useState('');
    const [showModalPopup, setShowModalPopup] = useState(false);
    const [isEditorReady, setIsEditorReady] = useState(false);
    const [formFields, setFormFields] = useState({
        businessUnit: '', rfxNumber: '', rfxTitle: '', rfxStatus: '', rfxType: '', rfxCategory: '', rfxQaDateTime: '', clientUrl: '', thirdPartyUrl: '',
        clientAgency: '', state: '', contact_name: '', country: '', contact_email: '', budgetAmount: '', submissionFormat: '', buyerName: '', buyerTitle: '',
        buyerContact: '', buyerEmail: '', document_type_id: '', fileName: '',
        //  version: '',
        documentDescription: '', id: '', bidData: '', vendorData: '', licenseData: '',
        conditionData: '', constraintsData: '', businessData: '', rfx_constraints_id: '', qa_due_date: '', qa_release_date: '', award_date: '', rfxdata: [], description: '',
        aiId: '', aiScope: '', bid: false, vendors: false, licenses: false, conditions: false, constraints: false, business: false
    });
    const [scope, setScope] = useState('');

    const [commentData, setCommentData] = useState([]);
    const handleClose = () => {
        setShow(!show)
        setEditId('')
        setFile()
        setFormFields({
            ...formFields, document_type_id: '', fileName: '',
            //  version: '',
            documentDescription: ''
        })
        setEditIndex(-1)
    }
    const [flag, setFlag] = useState(false);
    const [modalFlag, setModalFlag] = useState(false);
    const [editId, setEditId] = useState('')
    // Toast
    const { addToast } = useToasts();


    useEffect(() => {
        getCountryList()
        getBusinessUnitList()
        getRFxStatusList()
        getRFxCategoryList()
        getRFxTypeList()
        getDocumentTypeList()
        getPreRfxData()
    }, [])

    useEffect(() => {
        if (descriptionRef.current) {
            descriptionRef.current.setData(scope, {
                callback: function () {
                },
            });
        }
    }, [scope])


    const getPreRfxData = () => {
        setLoading(true)
        var method = 'GET'
        const apifetcherObj = APIMethod(`/pre-rfx/${location.state.detail ? location.state.detail : props.id}?rfx_type=${props.type}`, null, method)
        apifetcherObj
            .then(response => { return Promise.all([response.status, response.json()]) })
            .then(res => {
                let statusCode = res[0]
                let data = res[1]

                if (statusCode === 200) {
                    setLoading(false);
                    setFormFields({
                        ...formFields, rfxNumber: data.rfx_number, rfxStatus: data.rfx_status_id, rfxType: data.rfx_type_id,
                        rfxTitle: data.title, clientUrl: data.client_url, thirdPartyUrl: data.third_ps_url, clientAgency: data.rfx_client_agency,
                        submissionFormat: data.subbmission_format, buyerName: data.buyer_name, buyerTitle: data.buyer_title, buyerContact: data.buyer_contact,
                        //Commenting the following code for WOII-178. Divya. January 18 2024.. Code Start 
                        //buyerEmail: data.buyer_email, country: data.country_id, state: data.state_id, description: data.scope, contact_name : data.rfx_data.contract_name, 
                        // Commenting end. Divya. January 18 2024.. 
                        //Added this code for  WOII-178. Divya. January 18 2024.. Code Start 
                        buyerEmail: data.buyer_email, country: data.country_id, state: data.state_id, contact_name: data.rfx_data.contract_name,
                        // Divya. January 18 2024. Code End
                        contact_email: data.rfx_data.contract_email
                    })
                    setScope(formFields.description)
                    // console.log(data)
                    // console.log("in get prerfxdata from DB: "+data.contact_email);
                    getAiScope(data.id)
                    if (data.business_unit) {
                        formFields.businessUnit = data.business_unit
                    }
                    if (data.rfx_category) {
                        console.log(data)
                        formFields.rfxCategory = data.rfx_category
                    }

                    if (data.rfx_data != undefined) {
                        formFields.qa_due_date = data.rfx_data.qa_due_date ? moment(data.rfx_data.qa_due_date, 'YYYY-MM-DD').toDate() : null,
                            formFields.qa_release_date = data.rfx_data.qa_release_date ? moment(data.rfx_data.qa_release_date, 'YYYY-MM-DD').toDate() : null,
                            formFields.award_date = data.rfx_data.award_date ? moment(data.rfx_data.award_date, 'YYYY-MM-DD').toDate() : null,
                            formFields.budgetAmount = data.rfx_data.budget_amount
                    }
                    if (data.rfx_constraints != undefined) {
                        formFields.bidData = data.rfx_constraints.conference_mandatory,
                            formFields.vendorData = data.rfx_constraints.local_vendor_eligible,
                            formFields.licenseData = data.rfx_constraints.certification_license_needed,
                            formFields.conditionData = data.rfx_constraints.financial_condition,
                            formFields.constraintsData = data.rfx_constraints.other_constraints,
                            formFields.businessData = data.rfx_constraints.small_firms,
                            formFields.rfx_constraints_id = data.rfx_constraints.id,
                            formFields.bid = data.rfx_constraints.is_conference_mandatory
                        formFields.vendors = data.rfx_constraints.is_local_vendor_eligible
                        formFields.licenses = data.rfx_constraints.is_certification_license_needed
                        formFields.conditions = data.rfx_constraints.is_financial_condition
                        formFields.constraints = data.rfx_constraints.is_other_constraints
                        formFields.business = data.rfx_constraints.is_small_firms
                    }
                    if (data.country_id) {
                        getStateList(data.country_id, data.state_id)
                    }
                    if (data.documents != undefined) {
                        setAddDocuments(data.documents)
                    }
                    data.published ? setPublishedDate(moment(data.published, 'DD/MM/YYYY').toDate()) : null
                    data.due ? setDueDate(moment(data.due, 'DD/MM/YYYY hh:mm:ss').toDate()) : null
                    data.closing ? setClosingDate(moment(data.closing, 'DD/MM/YYYY hh:mm:ss').toDate()) : null
                    if (props.type == 'AI') {
                        formFields.aiId = data.id
                        props.onGetAiId(data.id)
                        getComments(data.refrence_id)
                    } else {
                        formFields.aiScope = data.scope
                        formFields.id = data.id
                        props.onGetSystemId(data.id)
                    }
                    if (props.type == 'SYSTEM' && !data.title) {
                        props.onGetSystemTitle(false)
                    }
                }
            })

            .catch(error => {
                setLoading(false);
            })
    }
    const getAiScope = (id) => {
        formFields.aiId = id
        setLoading(true)
        var method = 'GET'
        const apifetcherObj = APIMethod(`/rfp-image-segmentation/${id}`, null, method)
        apifetcherObj
            .then(response => { return Promise.all([response.status, response.json()]) })
            .then(res => {
                let statusCode = res[0]
                let data = res[1]

                if (statusCode === 200) {

                    formFields.aiScope = data.scope
                    setScope(data.scope)
                    setLoading(false);
                } else {
                    addToast(data.message, { appearance: 'error', autoDismiss: true });
                    setLoading(false);
                }
            })
            .catch(error => {
                alert(error)
                setLoading(false);
            })
    }

    const getBusinessUnitList = () => {
        const userId = localStorage.getItem('userId')
        setLoading(true)
        var method = 'GET'
        const apifetcherObj = APIMethod(`/business-units/user-business/list/${userId}`, null, method)
        apifetcherObj
            .then(response => { return Promise.all([response.status, response.json()]) })
            .then(res => {
                let statusCode = res[0]
                let data = res[1]

                if (statusCode === 200) {
                    setBusinessUnitData(data)
                    for (var i in data) {
                        if (data[i].is_checked == true) {
                            formFields.businessUnit = data[i].business_unit_id
                        }
                    }
                    // getPreRfxData()
                    setLoading(false)
                } else {
                    addToast(data.message, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
                }
            })
            .catch(error => {
                addToast(error, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
                setLoading(false)
            })
    }

    const getRFxStatusList = () => {
        setLoading(true)
        var method = 'GET'
        const apifetcherObj = APIMethod(`/rfx-statuses/all/list`, null, method)
        apifetcherObj
            .then(response => { return Promise.all([response.status, response.json()]) })
            .then(res => {
                let statusCode = res[0]
                let data = res[1]

                if (statusCode === 200) {
                    setRFxStatusData(data)
                    // getPreRfxData()
                    setLoading(false)
                } else {
                    addToast(data.message, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
                }
            })
            .catch(error => {
                addToast(error, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
                setLoading(false)
            })
    }

    const getCountryList = () => {
        setLoading(true)
        var method = 'GET'
        const apifetcherObj = APIMethod(`/region/country/list`, null, method)
        apifetcherObj
            .then(response => { return Promise.all([response.status, response.json()]) })
            .then(res => {
                let statusCode = res[0]
                let data = res[1]

                if (statusCode === 200) {
                    setCountryData(data)
                    setLoading(false)
                } else {
                    addToast(data.message, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
                }
            })
            .catch(error => {
                addToast(error, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
                setLoading(false)
            })
    }

    const getStateList = (id) => {
        var method = 'GET'
        const apifetcherObj = APIMethod(`/region/state/list/${id}`, null, method)
        apifetcherObj
            .then(response => { return Promise.all([response.status, response.json()]) })
            .then(res => {
                let statusCode = res[0]
                let data = res[1]

                if (statusCode === 200) {
                    setStateData(data)
                    setLoading(false)
                } else {
                    addToast(data.message, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
                }
            })
            .catch(error => {
                addToast(error, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
                setLoading(false)
            })

    }

    const onCountryHandler = (event) => {
        event.preventDefault();
        const { name, value } = event.target;
        const formFieldsObject = {
            ...formFields,
            [name]: value
        }
        setFormFields(formFieldsObject);
        formFields.state = '';
        getStateList(value)
    }

    const getRFxCategoryList = () => {
        const userId = localStorage.getItem('userId')
        setLoading(true)
        var method = 'GET'
        const apifetcherObj = APIMethod(`/rfx-categories/user-category/list/${userId}`, null, method)
        apifetcherObj
            .then(response => { return Promise.all([response.status, response.json()]) })
            .then(res => {
                let statusCode = res[0]
                let data = res[1]

                if (statusCode === 200) {
                    setRFxCategoryData(data)
                    setLoading(false)
                } else {
                    addToast(data.message, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
                }
            })
            .catch(error => {
                addToast(error, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
                setLoading(false)
            })
    }

    const getRFxTypeList = () => {
        setLoading(true)
        var method = 'GET'
        const apifetcherObj = APIMethod(`/rfx-types/all/list`, null, method)
        apifetcherObj
            .then(response => { return Promise.all([response.status, response.json()]) })
            .then(res => {
                let statusCode = res[0]
                let data = res[1]

                if (statusCode === 200) {
                    setRFxTypeData(data)
                    getPreRfxData()
                    setLoading(false)
                } else {
                    addToast(data.message, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
                }
            })
            .catch(error => {
                addToast(error, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
                setLoading(false)
            })
    }

    const handleChange = (event) => {
        event.preventDefault();
        const { name, value } = event.target;
        // console.log(name)
        // console.log(value)
        const formFieldsObject = {
            ...formFields,
            [name]: value
        }
        setFormFields(formFieldsObject);
    }


    const getDocumentTypeList = () => {
        setLoading(true)
        var method = 'GET'
        const apifetcherObj = APIMethod(`/rfx-document-type/all/list`, null, method)
        apifetcherObj
            .then(response => { return Promise.all([response.status, response.json()]) })
            .then(res => {
                let statusCode = res[0]
                let data = res[1]

                if (statusCode === 200) {
                    setDocumentList(data)
                    setLoading(false)
                } else {
                    addToast(data.message, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
                }
            })
            .catch(error => {
                addToast(error, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
                setLoading(false)
            })
    }

    const _onAddDocument = () => {
        if (formFields.document_type_id === ''
            // || formFields.version === ''
            || file === undefined || formFields.documentDescription === '') {
            setModalFlag(true)
            addToast('Please fill all the fields', { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
        } else {
            fileUpload()
        }
    }

    const fileUpload = () => {
        setButtonLoader(true)
        if (file.type) {
            var method = 'POST'
            var form = new FormData();
            form.append('file', file);
            const apifetcherObj = FileUploadMethod(`/document/attachment/upload`, form, method)
            apifetcherObj
                .then(response => { return Promise.all([response.status, response.json()]) })
                .then(res => {
                    let statusCode = res[0]
                    let data = res[1]
                    var name = ""
                    if (statusCode === 200) {
                        for (var i in documentList) {
                            if (documentList[i].id == formFields.document_type_id) {
                                name = documentList[i].name
                            }
                        }
                        var obj = {
                            document_id: formFields.document_type_id,
                            document_type_name: name,
                            file_name: file.name,
                            // version: formFields.version,
                            file: data.file,
                            description: formFields.documentDescription
                        }

                        if (editIndex >= 0) {
                            for (var index in addDocuments) {
                                if (index == editIndex) {
                                    addDocuments.splice(editIndex, 1);
                                    addDocuments.splice(editIndex, 0, obj);
                                    setEditIndex(-1)
                                    setAddDocuments(addDocuments)
                                    break;
                                }
                            }
                        } else {
                            if (addDocuments.length > 0) {
                                var hasError = false;
                                for (var i in addDocuments) {
                                    if (formFields.document_type_id == '15' && addDocuments[i].document_id == '15') {
                                        hasError = true;
                                    }

                                    if (formFields.document_type_id == '140' && addDocuments[i].document_id == '140') {
                                        hasError = true;
                                    }
                                }

                                if (hasError) {
                                    addToast('You can not add this with document type', { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
                                    setButtonLoader(false)
                                    return;
                                }
                            }

                            addDocuments.push(obj)
                            setAddDocuments(addDocuments)
                        }

                        handleClose()
                        setEditIndex(-1)
                        setLoading(false)
                        setFormFields({
                            ...formFields, document_type_id: '', fileName: '',
                            //  version: '',
                            documentDescription: ''
                        })
                        setFile('')
                        setButtonLoader(false)
                    } else {
                        addToast(data.message, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
                        setButtonLoader(false)
                    }
                })
                .catch(error => {
                    setButtonLoader(false)
                })
        } else {
            var name = ''
            for (var i in documentList) {
                if (documentList[i].id == formFields.document_type_id) {
                    name = documentList[i].name
                }
            }

            var hasError = false;
            for (var i in addDocuments) {
                if (addDocuments[i].document_id == '15' && formFields.document_type_id == '15' && i != editIndex) {
                    hasError = true;
                }

                if (addDocuments[i].document_id == '140' && formFields.document_type_id == '140' && i != editIndex) {
                    hasError = true;
                }
            }



            if (hasError) {
                addToast('You can not add this with document type', { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
                setButtonLoader(false)
                return;
            }

            for (var i in addDocuments) {
                if (i == editIndex) {
                    addDocuments[i].document_id = formFields.document_type_id,
                        addDocuments[i].document_type_name = name,
                        addDocuments[i].file_name = file.name,
                        // addDocuments[i].version = formFields.version,
                        addDocuments[i].description = formFields.documentDescription
                }
            }


            setShow(!show)
            setEditIndex(-1)
            setFormFields({
                ...formFields, document_id: '', fileName: '',
                //  version: '', 
                description: ''
            })
            setFile('')
            setButtonLoader(false)
            // }
        }
    }

    const filehandler = event => {
        setFile(event.target.files[0])
    }

    const _onEdit = (index, data) => {
        setEditId(data.id)
        setFormFields({
            ...formFields, document_type_id: data.document_id, fileName: data.file_name,
            //  version: data.version, 
            documentDescription: data.description
        })
        setFile({ name: data.file_name })
        setShow(!show)
        setEditIndex(index)
    }

    const save = () => {
        var qa_due_date = formFields.qa_due_date == null ? '' : moment(formFields.qa_due_date).format('YYYY-MM-DD')
        var qa_release_date = formFields.qa_release_date == null ? '' : moment(formFields.qa_release_date).format('YYYY-MM-DD')
        var award_date = formFields.award_date == null ? '' : moment(formFields.award_date).format('YYYY-MM-DD')
        var closing_date = closingDate == '' ? '' : moment(closingDate).format('DD/MM/YYYY HH:mm:ss')
        var published_date = publishedDate == '' ? '' : moment(publishedDate).format('DD/MM/YYYY')
        var due_date = dueDate == '' ? '' : moment(dueDate).format('DD/MM/YYYY HH:mm:ss');
        var amount = formFields.budgetAmount === undefined ? '0.00' : formFields.budgetAmount + '.00'
        var isRfx = false;
        if (checkEmptyString(formFields.businessUnit) || checkEmptyString(formFields.rfxNumber) || checkEmptyString(formFields.rfxTitle) || checkEmptyString(formFields.rfxType) ||
            checkEmptyString(formFields.rfxCategory) || checkEmptyString(formFields.clientAgency) || checkEmptyString(descriptionRef.current.getData()) || checkEmptyString(formFields.thirdPartyUrl) ||
            checkEmptyString(formFields.submissionFormat) || checkEmptyString(formFields.buyerName) || checkEmptyString(formFields.buyerTitle) || checkEmptyString(formFields.buyerContact) || checkEmptyString(formFields.buyerEmail) ||
            qa_due_date === '' || qa_release_date === '' || award_date === '' || closing_date === '' || published_date === '' || due_date === '') {
            setFlag(true)
            addToast('Please fill all the fields', { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
        } else if (!Regex.test(formFields.clientUrl) && formFields.clientUrl.toLowerCase() != 'n/a') {
            addToast('Please enter valid RFx Client Agency Source URL', { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
        } else if (!Regex.test(formFields.thirdPartyUrl) && formFields.thirdPartyUrl.toLowerCase() != 'n/a') {
            addToast('Please enter valid RFx 3rd Party Source URL', { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
        } else if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(formFields.contact_email) && formFields.contact_email.toLowerCase() != 'n/a') {
            addToast('Please enter valid Rfx Contact Email', { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
        } else if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(formFields.buyerEmail) && formFields.buyerEmail.toLowerCase() != 'n/a') {
            addToast('Please enter valid Buyer Email', { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
        } else if (addDocuments.length > 0) {
            for (var i in addDocuments) {
                if (addDocuments[i].document_id == '15') {
                    isRfx = true;
                    break;
                }
            }
            if (isRfx) {
                setButtonLoader(true)
                var method = 'PUT'
                // console.log("In save from FE: "+formFields.contact_email)
                var params = JSON.stringify({
                    id: props.type == 'AI' ? formFields.aiId : location.state.detail,
                    business_unit: formFields.businessUnit,
                    rfx_number: formFields.rfxNumber,
                    title: formFields.rfxTitle,
                    owner: localStorage.getItem('userName'),
                    published: published_date,
                    due: due_date,
                    closing: closing_date,
                    rfx_category: formFields.rfxCategory ? parseInt(formFields.rfxCategory) : '',
                    rfx_client_agency: formFields.clientAgency,
                    client_url: formFields.clientUrl,
                    third_ps_url: formFields.thirdPartyUrl,
                    country_id: formFields.country ? parseInt(formFields.country) : '',
                    state_id: formFields.state ? parseInt(formFields.state) : '',
                    scope: descriptionRef.current.getData(),
                    rfx_type_id: formFields.rfxType ? parseInt(formFields.rfxType) : '',
                    subbmission_format: formFields.submissionFormat,
                    buyer_name: formFields.buyerName,
                    buyer_title: formFields.buyerTitle,
                    buyer_contact: formFields.buyerContact,
                    buyer_email: formFields.buyerEmail,
                    documents: addDocuments,
                    rfx_type: props.type,
                    rfx_constraints: {
                        id: formFields.rfx_constraints_id,
                        is_conference_mandatory: formFields.bid,
                        is_local_vendor_eligible: formFields.vendors,
                        is_certification_license_needed: formFields.licenses,
                        is_financial_condition: formFields.conditions,
                        is_other_constraints: formFields.constraints,
                        is_small_firms: formFields.business,
                        conference_mandatory: formFields.bidData,
                        local_vendor_eligible: formFields.vendorData,
                        certification_license_needed: formFields.licenseData,
                        financial_condition: formFields.conditionData,
                        other_constraints: formFields.constraintsData,
                        small_firms: formFields.businessData
                    },
                    rfx_data: {
                        qa_due_date: qa_due_date,
                        qa_release_date: qa_release_date,
                        award_date: award_date,
                        contract_name: formFields.contact_name,
                        contract_email: formFields.contact_email,
                        budget_amount: amount
                    }
                })
                const apifetcherObj = APIMethod(`/pre-rfx`, params, method)
                apifetcherObj
                    .then(response => { return Promise.all([response.status, response.json()]) })
                    .then(res => {
                        let statusCode = res[0]
                        let data = res[1]

                        if (statusCode === 200) {
                            setButtonLoader(false)
                            getPreRfxData()
                            addToast('RFx Details Successfully Saved', { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'success', autoDismiss: true });
                            goBack()
                        } else {
                            addToast(data.message, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
                            setButtonLoader(false)
                        }
                    })
                    .catch(error => {
                        setButtonLoader(false)
                    })
                var method = 'PUT'
                var params = JSON.stringify({
                    scope: descriptionRef.current.getData(),
                })
                const apifetcherObj1 = APIMethod(`/rfp-image-segmentation/${formFields.aiId}`, params, method)
                apifetcherObj1
                    .then(response => { return Promise.all([response.status, response.json()]) })
                    .then(res => {
                        let statusCode = res[0]
                        let data = res[1]

                        if (statusCode == 200) {
                            addToast(data.message, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'success', autoDismiss: true })
                            formFields.aiImageSegemtationLoader = false;
                        } else {
                            addToast(data.message, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
                            formFields.aiImageSegemtationLoader = false;
                        }
                    })
                    .catch(error => {
                        addToast(error, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
                        formFields.aiImageSegemtationLoader = false;
                    })

            }
            else {
                addToast(`Please Add RFP Document it's mandatory`, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
            }
        } else {
            addToast(`Please Add RFP Document it's mandatory`, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
        }
    }

    const deleteDocument = (index, id) => {
        if (id) {
            setDeleteId(id)
            isShowPopup(true)
        } else {
            setDeleteIndex(index)
            setDeleteModal(!showDeleteModal)
        }
    }

    const isShowPopup = (status) => {
        setShowModalPopup(status)
    };

    const deleteLocalDocument = () => {
        setDeleteIndex(deleteIndex)
        addDocuments.splice(deleteIndex, 1);
        var temp = [];
        for (var j in addDocuments) {
            temp.push(addDocuments[j])
            if (j == addDocuments.length - 1) {
                setAddDocuments(temp)
                setDeleteModal(!showDeleteModal)
            }
        }
        if (addDocuments.length == 0) {
            setAddDocuments(temp)
            setDeleteModal(!showDeleteModal)
        }
        setDeleteIndex('')

    }

    const handleDate = (date, type) => {
        const obj = {
            ...formFields,
            [type]: date
        }
        setFormFields(obj);
    }

    const handlePhone = (event, type) => {
        const formFieldsObject = {
            ...formFields,
            [type]: event
        }
        setFormFields(formFieldsObject);
    }

    let nonEditableConfig = {
        events: {
            initialized() {
                const editor = this;
                editor.edit.off();

                setTimeout(() => {
                    editor.edit.off();
                }, 5000);
            }
        }
    };

    const getComments = (id) => {
        setLoading(true)
        var method = 'GET'
        const apifetcherObj = APIMethod(`/pre-rfx/comments/${id}?comment_type=PRE_RFX`, null, method)
        apifetcherObj
            .then(response => { return Promise.all([response.status, response.json()]) })
            .then(res => {
                let statusCode = res[0]
                let data = res[1]

                if (statusCode === 200) {
                    setCommentData(data)
                    setLoading(false)
                } else {
                    addToast(data.message, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
                }
            })
            .catch(error => {
                addToast(error, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
                setLoading(false)
            })
    }

    console.log(formFields['rfxCategory'])
    return (
        <>
            <Tab.Pane eventKey="RFxDataDtl">
                <div className="card card-body mt-4">
                    <div className="row">
                        <div className="col-lg-4 col-md-6">
                            <div className="form-group">
                                <label>Business Unit</label>
                                <select id="disabledSelect" className="form-control custom-select" value={formFields['businessUnit']} name="businessUnit" onChange={handleChange} disabled={props.page == '/view-parse-rfx-detail' ? true : false}>
                                    <option value="" disabled selected>Select your option</option>
                                    {
                                        businessUnitData.map((res) => {
                                            return (
                                                <option value={res.id}>{res.name}</option>
                                            )
                                        })
                                    }
                                </select>
                                <span className="text-danger">{flag === true && checkEmptyString(formFields.businessUnit) ? 'Please Select Business Unit' : null}</span>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="form-group">
                                <label>RFx Number</label>
                                <input type="text" className="form-control" onChange={handleChange} name="rfxNumber" value={formFields['rfxNumber']} disabled={props.page == '/view-parse-rfx-detail' ? true : false} />
                                <span className="text-danger">{flag === true && checkEmptyString(formFields.rfxNumber) ? 'Please Enter RFx Number' : null}</span>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="form-group">
                                <label>RFx Title</label>
                                <input type="text" className="form-control" onChange={handleChange} name="rfxTitle" value={formFields['rfxTitle']} disabled={props.page == '/view-parse-rfx-detail' ? true : false} />
                                <span className="text-danger">{flag === true && checkEmptyString(formFields.rfxTitle) ? 'Please Enter RFx Title' : null}</span>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="form-group">
                                <label>RFx Type</label>
                                <select id="disabledSelect" className="form-control custom-select" value={formFields['rfxType']} name="rfxType" onChange={handleChange} disabled={props.page == '/view-parse-rfx-detail' ? true : false}>
                                    <option value="" disabled selected>Select your option</option>
                                    {
                                        RFxTypeData.map((res) => {
                                            return (
                                                <option value={res.id}>{res.name}</option>
                                            )
                                        })
                                    }
                                </select>
                                <span className="text-danger">{flag === true && checkEmptyString(formFields.rfxType) ? 'Please Select RFx Type' : null}</span>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="form-group">
                                <label>RFx Category</label>
                                <select id="disabledSelect" className="form-control custom-select" value={formFields['rfxCategory']} name="rfxCategory" onChange={handleChange} disabled={props.page == '/view-parse-rfx-detail' ? true : false}>
                                    <option value="" disabled selected>Select your option</option>
                                    {
                                        RFxCategoryData.map((res) => {
                                            return (
                                                <option value={res.id}>{res.name}</option>
                                            )
                                        })
                                    }
                                </select>
                                <span className="text-danger">{flag === true && checkEmptyString(formFields.rfxCategory) ? 'Please Select RFx Category' : null}</span>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="form-group">
                                <label>RFx Published Date</label>
                                <DatePicker
                                    onChangeRaw={(e) => e.preventDefault()}
                                    selected={publishedDate}
                                    onChange={(date) => setPublishedDate(date)}
                                    className="form-control"
                                    placeholder="select Date"
                                    dateFormat="MM-dd-yyyy"
                                    readOnly={props.page == '/view-parse-rfx-detail' ? true : false}
                                    scrollableYearDropdown
                                    showYearDropdown
                                />
                                <span className="text-danger">{flag === true && checkEmptyString(publishedDate) ? 'Please Select RFx Published Date' : null}</span>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="form-group">
                                <label>RFx Q&A Due Date</label>
                                <DatePicker
                                    onChangeRaw={(e) => e.preventDefault()}
                                    selected={formFields.qa_due_date}
                                    onChange={(date) => {
                                        if (publishedDate == '') {
                                            addToast('Please Select RFx Published Date First.', { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
                                        } else {
                                            handleDate(date, 'qa_due_date')
                                        }
                                    }}
                                    className="form-control"
                                    placeholder="select Date"
                                    dateFormat="MM-dd-yyyy"
                                    minDate={publishedDate}
                                    readOnly={props.page == '/view-parse-rfx-detail' ? true : false}
                                    scrollableYearDropdown
                                    showYearDropdown
                                />
                                <span className="text-danger">{flag === true && checkEmptyString(formFields.qa_due_date) ? 'Please Select RFx Q&A Due Date' : null}</span>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="form-group">
                                <label>RFx Q&A Release Date</label>
                                <DatePicker
                                    onChangeRaw={(e) => e.preventDefault()}
                                    selected={formFields.qa_release_date}
                                    onChange={(date) => {
                                        if (formFields.qa_due_date == '') {
                                            addToast('Please Select RFx Q&A Due Date First.', { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
                                        } else {
                                            handleDate(date, 'qa_release_date')
                                        }
                                    }
                                    }
                                    className="form-control"
                                    placeholder="select Date"
                                    dateFormat="MM-dd-yyyy"
                                    minDate={formFields.qa_due_date}
                                    readOnly={props.page == '/view-parse-rfx-detail' ? true : false}
                                    scrollableYearDropdown
                                    showYearDropdown
                                />
                                <span className="text-danger">{flag === true && checkEmptyString(formFields.qa_release_date) ? 'Please Select RFx Q&A Release Date' : null}</span>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="form-group">
                                <label>RFx Due Date & Time</label>
                                <DatePicker
                                    onChangeRaw={(e) => e.preventDefault()}
                                    selected={dueDate}
                                    onChange={(date) => {
                                        if (formFields.qa_release_date == '') {
                                            addToast('Please Select RFx Q&A Release Date First.', { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
                                        } else {
                                            setDueDate(date)
                                        }
                                    }}
                                    className="form-control"
                                    showTimeSelect
                                    timeFormat="HH:mm"
                                    timeIntervals={15}
                                    timeCaption="Time"
                                    minDate={formFields.qa_release_date}
                                    dateFormat="MMMM d, yyyy h:mm aa"
                                    placeholderText="Select Date & Time"
                                    readOnly={props.page == '/view-parse-rfx-detail' ? true : false}
                                    scrollableYearDropdown
                                    showYearDropdown
                                />
                                <span className="text-danger">{flag === true && checkEmptyString(dueDate) ? 'Please Select RFx Due Date & Time' : null}</span>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="form-group">
                                <label>RFx Closing Date & Time</label>
                                <DatePicker
                                    onChangeRaw={(e) => e.preventDefault()}
                                    className="form-control"
                                    selected={closingDate}
                                    onChange={(date) => {
                                        if (formFields.qa_release_date == '') {
                                            addToast('Please Select RFx Q&A Release Date First.', { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
                                        } else {
                                            setClosingDate(date)
                                        }
                                    }}
                                    showTimeSelect
                                    timeFormat="HH:mm"
                                    timeIntervals={15}
                                    timeCaption="Time"
                                    minDate={formFields.qa_release_date}
                                    dateFormat="MMMM d, yyyy h:mm aa"
                                    placeholderText="Select Date & Time"
                                    readOnly={props.page == '/view-parse-rfx-detail' ? true : false}
                                    scrollableYearDropdown
                                    showYearDropdown
                                />
                                <span className="text-danger">{flag === true && checkEmptyString(closingDate) ? 'Please Select RFx Closing Date & Time' : null}</span>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="form-group">
                                <label>RFx Award Date</label>
                                <DatePicker
                                    onChangeRaw={(e) => e.preventDefault()}
                                    selected={formFields.award_date}
                                    onChange={(date) => {
                                        if (closingDate == '') {
                                            addToast('Please Select RFx Closing Date & Time First.', { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
                                        } else {
                                            handleDate(date, 'award_date')
                                        }
                                    }}
                                    className="form-control"
                                    placeholder="select Date"
                                    dateFormat="MM-dd-yyyy"
                                    minDate={closingDate}
                                    readOnly={props.page == '/view-parse-rfx-detail' ? true : false}
                                    scrollableYearDropdown
                                    showYearDropdown
                                />
                                <span className="text-danger">{flag === true && checkEmptyString(formFields.award_date) ? 'Please Select RFx Award Date' : null}</span>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="form-group">
                                <label>RFx Client Agency Source URL</label>
                                <input type="text" className="form-control" onChange={handleChange} name='clientUrl' value={formFields['clientUrl']} disabled={props.page == '/view-parse-rfx-detail' ? true : false} />
                                <span className="text-danger">{flag === true && checkEmptyString(formFields.clientUrl) ? 'Please Enter RFx Client Agency Source URL' : null}</span>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="form-group">
                                <label>RFx 3rd Party Source URL</label>
                                <input type="text" className="form-control" onChange={handleChange} name='thirdPartyUrl' value={formFields['thirdPartyUrl']} disabled={props.page == '/view-parse-rfx-detail' ? true : false} />
                                <span className="text-danger">{flag === true && checkEmptyString(formFields.thirdPartyUrl) ? 'Please Enter RFx 3rd Party Source URL' : null}</span>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="form-group">
                                <label>RFx Client Agency</label>
                                <input type="text" className="form-control" onChange={handleChange} name='clientAgency' value={formFields['clientAgency']} disabled={props.page == '/view-parse-rfx-detail' ? true : false} />
                                <span className="text-danger">{flag === true && checkEmptyString(formFields.clientAgency) ? 'Please Enter RFx Client Agency' : null}</span>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="form-group">
                                <label>RFx Contact Name</label>
                                <input type="text" className="form-control" onChange={handleChange} name='contact_name' value={formFields['contact_name']} disabled={props.page == '/view-parse-rfx-detail' ? true : false} />
                                <span className="text-danger">{flag === true && checkEmptyString(formFields.contact_name) ? 'Please Enter RFx Contact Name' : null}</span>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="form-group">
                                <label>RFx Contact Email</label>
                                <input type="text" className="form-control" onChange={handleChange} name='contact_email' value={formFields['contact_email']} disabled={props.page == '/view-parse-rfx-detail' ? true : false} />
                                <span className="text-danger">{flag === true && checkEmptyString(formFields.contact_email) ? 'Please Enter RFx Contact Email' : null}</span>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="form-group">
                                <label>Country</label>
                                <select id="disabledSelect" className="form-control custom-select" value={formFields['country']} name="country" onChange={onCountryHandler} disabled={props.page == '/view-parse-rfx-detail' ? true : false}>
                                    <option value="" disabled selected>Select your option</option>
                                    {
                                        countryData.map((val, index) => {
                                            return (
                                                <option key={index} value={val.id}>{val.name}</option>
                                            )
                                        })
                                    }
                                </select>
                                <span className="text-danger">{flag === true && checkEmptyString(formFields.country) ? 'Please Select Country' : null}</span>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="form-group">
                                <label>State</label>
                                <select id="disabledSelect" className="form-control custom-select" value={formFields['state']} name="state" onChange={handleChange} disabled={props.page == '/view-parse-rfx-detail' ? true : false}>
                                    <option value="" disabled selected>Select your option</option>
                                    {
                                        stateData.map((res, index) => {
                                            return (
                                                <option key={index} value={res.id}>{res.name}</option>
                                            )
                                        })
                                    }
                                </select>
                                <span className="text-danger">{flag === true && checkEmptyString(formFields.state) ? 'Please Select State' : null}</span>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="form-group">
                                <label>RFx Budget Amount</label>
                                <input type="text" className="form-control" onChange={handleChange} onKeyPress={(event) => {
                                    if (!/[0-9]/.test(event.key)) {
                                        event.preventDefault();
                                        return false;
                                    }

                                    return true;
                                }} name='budgetAmount' value={formFields['budgetAmount']} disabled={props.page == '/view-parse-rfx-detail' ? true : false} />
                                <span className="text-danger">{flag === true && checkEmptyString(formFields.budgetAmount) ? 'Please Enter RFx Budget Amount' : null}</span>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="form-group">
                                <label>RFx Submission Format Selection</label>
                                <select id="disabledSelect" className="form-control custom-select" value={formFields['submissionFormat']} name="submissionFormat" onChange={handleChange} disabled={props.page == '/view-parse-rfx-detail' ? true : false}>
                                    <option value="" disabled selected>Select your option</option>
                                    <option>Online</option>
                                    <option>Email</option>
                                    <option>Mail</option>
                                    <option>Email and Mail</option>
                                </select>
                                <span className="text-danger">{flag === true && checkEmptyString(formFields.submissionFormat) ? 'Please Select RFx Submission Format Selection' : null}</span>
                            </div>
                        </div>
                        <div className="col-md-12 ">
                            <div className="form-group">
                                {/* Commenting the following code for WOII-73. Divya. September 01 2023.. Code Start */}
                                {/*<label>Description/Scope</label>*/}
                                {/* Commenting end. Divya. September 01 2023.. */}
                                {/* Added this code for  WOII-73. Divya. September 01 2023.. Code Start */}
                                <p className="font-20 font-bold">Description/Scope</p>
                                {/* Divya. September 01 2023. Code End */}
                                {/* <Editor
                                    apiKey={tiny_key}
                                    onInit={(evt, editor) => descriptionRef.current = editor}
                                    disabled={props.page == '/view-parse-rfx-detail' ? true : false}
                                    //Commenting the following code for WOII-178. Divya. January 18 2024.. Code Start 
                                   // initialValue={formFields.description}
                                    // Commenting end. Divya. January 18 2024.. 
                                    //Added this code for  WOII-178. Divya. January 18 2024.. Code Start 
                                    initialValue={scope}
                                   // Divya. January 18 2024. Code End
                                    onChange={(e, editor) => {
                                        setScope(editor.getContent());
                                    }}                                    
                                    init={{
                                        height: 500,
                                        menubar: 'file edit view insert format tools table tc help',
                                        selector: 'textarea#full-featured',
                                        plugins: 'print preview importcss paste searchreplace autolink autosave save directionality visualblocks visualchars fullscreen image  table lists ',
                                        toolbar: 'undo redo |  blocks | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist checklist | forecolor backcolor casechange  | fullscreen  preview | image paste',
                                        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:16px }'
                                    }}
                                /> */}
                                <CKEditor
                                    initData={scope}
                                    readOnly={props.page === '/view-parse-rfx-detail'}
                                    onInstanceReady={(event) => descriptionRef.current = event.editor}
                                    // onChange={(event) => {
                                    //     const data = event.editor.getData();
                                    //     setScope(data);
                                    // }}
                                    config={{
                                        height: 500,
                                        versionCheck: false,
                                        toolbar: [
                                            { name: 'document', items: ['Source', 'Save', 'NewPage', 'Preview', 'Print', 'Templates'] },
                                            { name: 'clipboard', items: ['Cut', 'Copy', 'Paste', 'PasteText', 'PasteFromWord', 'Undo', 'Redo'] },
                                            { name: 'editing', items: ['Find', 'Replace', 'SelectAll', 'Scayt'] },
                                            { name: 'forms', items: ['Form', 'Checkbox', 'Radio', 'TextField', 'Textarea', 'Select', 'Button', 'ImageButton', 'HiddenField'] },
                                            { name: 'basicstyles', items: ['Bold', 'Italic', 'Underline', 'Strike', 'Subscript', 'Superscript', 'RemoveFormat'] },
                                            { name: 'paragraph', items: ['NumberedList', 'BulletedList', 'Outdent', 'Indent', 'Blockquote', 'CreateDiv', 'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock', 'BidiLtr', 'BidiRtl', 'Language'] },
                                            { name: 'links', items: ['Link', 'Unlink', 'Anchor'] },
                                            { name: 'insert', items: ['Image', 'Flash', 'Table', 'HorizontalRule', 'Smiley', 'SpecialChar', 'PageBreak', 'Iframe'] },
                                            { name: 'styles', items: ['Styles', 'Format', 'Font', 'FontSize'] },
                                            { name: 'colors', items: ['TextColor', 'BGColor'] },
                                            { name: 'tools', items: ['Maximize', 'ShowBlocks', 'About'] }
                                        ],
                                    }}
                                />
                                <span className="text-danger">{flag === true && checkEmptyString(descriptionRef.current.getData()) ? 'Please Enter Description/Scope' : null}</span>
                            </div>
                        </div>
                        <div className="col-md-12">
                            {/* Commenting the following code for WOII-73. Divya. September 01 2023.. Code Start */}
                            {/*<p className="font-medium">RFx Constraints:</p>*/}
                            {/* Commenting end. Divya. September 01 2023.. */}
                            {/* Added this code for  WOII-73. Divya. September 01 2023.. Code Start */}
                            <p className="font-20 font-bold">RFx Constraints:</p>
                            {/* Divya. September 01 2023. Code End */}
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <lable className="">Pre-Bid Conference mandatory? </lable><br />
                                <Toggle
                                    checked={formFields.bid}
                                    icons={false}
                                    className='custom-toggle-classname'
                                    onChange={() => handlePhone(!formFields.bid, 'bid')}
                                    disabled={props.page == '/view-parse-rfx-detail' ? true : false} />
                                {
                                    formFields.bid === true ?
                                        <div className="form-style mt-2">
                                            {/* Commenting the following code for WI-3. Divya. June 21 2023.. Code Start */}
                                            {/*<input type="text" className="form-control" style={{ height: "38px" }} onChange={handleChange} name="bidData" value={formFields['bidData']} disabled={props.page == '/view-parse-rfx-detail' ? true : false} />*/}
                                            {/* Commenting end. Divya. June 21 2023.. */}
                                            {/* Added this code for WI-3. Divya. June 21 2023.. Code Start */}
                                            {/* Commenting the following code for WOII-68. Divya. August 21 2023.. Code Start */}
                                            {/*<input type="text" className="form-control" style={{ height: "80px" }} onChange={handleChange} name="bidData" value={formFields['bidData']} disabled={props.page == '/view-parse-rfx-detail' ? true : false} />*/}
                                            {/* Commenting end. Divya. August 21 2023.. */}
                                            {/* Added this code for WOII-68. Divya. August 21 2023.. Code Start */}
                                            <textarea className="form-control" style={{ height: "100px", overflow: "auto" }} onChange={handleChange} name="bidData" value={formFields['bidData']} disabled={props.page == '/view-parse-rfx-detail' ? true : false} />
                                            {/* Divya. August 21 2023. Code End */}
                                            {/* Divya. June 21 2023. Code End */}
                                        </div> : null
                                }
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <lable className="">Only local vendors eligible?</lable><br />
                                <Toggle
                                    checked={formFields.vendors}
                                    icons={false}
                                    className='custom-toggle-classname'
                                    onChange={() => handlePhone(!formFields.vendors, 'vendors')}
                                    disabled={props.page == '/view-parse-rfx-detail' ? true : false} />
                                {
                                    formFields.vendors === true ?
                                        <div className="form-style mt-2">
                                            {/* Commenting the following code for WI-3. Divya. June 21 2023.. Code Start */}
                                            {/*<input type="text" className="form-control" style={{ height: "38px" }} onChange={handleChange} name="vendorData" value={formFields['vendorData']} disabled={props.page == '/view-parse-rfx-detail' ? true : false} />*/}
                                            {/* Commenting end. Divya. June 21 2023.. */}
                                            {/* Added this code for WI-3. Divya. June 21 2023.. Code Start */}
                                            {/* Commenting the following code for WOII-68. Divya. August 21 2023.. Code Start */}
                                            {/*<input type="text" className="form-control" style={{ height: "80px" }} onChange={handleChange} name="vendorData" value={formFields['vendorData']} disabled={props.page == '/view-parse-rfx-detail' ? true : false} /> */}
                                            {/* Commenting end. Divya. August 21 2023.. */}
                                            {/* Added this code for WOII-68. Divya. August 21 2023.. Code Start */}
                                            <textarea className="form-control" style={{ height: "100px", overflow: "auto" }} onChange={handleChange} name="vendorData" value={formFields['vendorData']} disabled={props.page == '/view-parse-rfx-detail' ? true : false} />
                                            {/* Divya. August 21 2023. Code End */}
                                            {/* Divya. June 21 2023. Code End */}
                                        </div> : null
                                }
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <lable className="">Any Certification/licenses needed?</lable><br />
                                <Toggle
                                    checked={formFields.licenses}
                                    icons={false}
                                    className='custom-toggle-classname'
                                    onChange={() => handlePhone(!formFields.licenses, 'licenses')}
                                    disabled={props.page == '/view-parse-rfx-detail' ? true : false} />
                                {
                                    formFields.licenses === true ?
                                        <div className="form-style mt-2">
                                            {/* Commenting the following code for WI-3. Divya. June 21 2023.. Code Start */}
                                            {/*<input type="text" className="form-control" style={{ height: "38px" }} onChange={handleChange} name="licenseData" value={formFields['licenseData']} disabled={props.page == '/view-parse-rfx-detail' ? true : false} />*/}
                                            {/* Commenting end. Divya. June 21 2023.. */}
                                            {/* Added this code for WI-3. Divya. June 21 2023.. Code Start */}
                                            {/* Commenting the following code for WOII-68. Divya. August 21 2023.. Code Start */}
                                            {/*<input type="text" className="form-control" style={{ height: "80px" }} onChange={handleChange} name="licenseData" value={formFields['licenseData']} disabled={props.page == '/view-parse-rfx-detail' ? true : false} />*/}
                                            {/* Commenting end. Divya. August 21 2023.. */}
                                            {/* Added this code for WOII-68. Divya. August 21 2023.. Code Start */}
                                            <textarea className="form-control" style={{ height: "100px", overflow: "auto" }} onChange={handleChange} name="licenseData" value={formFields['licenseData']} disabled={props.page == '/view-parse-rfx-detail' ? true : false} />
                                            {/* Divya. August 21 2023. Code End */}
                                            {/* Divya. June 21 2023. Code End */}
                                        </div> : null
                                }
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <lable className="">Any financial conditions?</lable><br />
                                <Toggle
                                    checked={formFields.conditions}
                                    icons={false}
                                    className='custom-toggle-classname'
                                    onChange={() => handlePhone(!formFields.conditions, 'conditions')}
                                    disabled={props.page == '/view-parse-rfx-detail' ? true : false} />
                                {
                                    formFields.conditions === true ?
                                        <div className="form-style mt-2">
                                            {/* Commenting the following code for WI-3. Divya. June 21 2023.. Code Start */}
                                            {/*<input type="text" className="form-control" style={{ height: "38px" }} onChange={handleChange} name="conditionData" value={formFields['conditionData']} disabled={props.page == '/view-parse-rfx-detail' ? true : false} />*/}
                                            {/* Commenting end. Divya. June 21 2023.. */}
                                            {/* Added this code for WI-3. Divya. June 21 2023.. Code Start */}
                                            {/* Commenting the following code for WOII-68. Divya. August 21 2023.. Code Start */}
                                            {/*<input type="text" className="form-control" style={{ height: "80px"}} onChange={handleChange} name="conditionData" value={formFields['conditionData']} disabled={props.page == '/view-parse-rfx-detail' ? true : false} />*/}
                                            {/* Commenting end. Divya. August 21 2023.. */}
                                            {/* Added this code for WOII-68. Divya. August 21 2023.. Code Start */}
                                            <textarea className="form-control" style={{ height: "100px", overflow: "auto" }} onChange={handleChange} name="conditionData" value={formFields['conditionData']} disabled={props.page == '/view-parse-rfx-detail' ? true : false} />
                                            {/* Divya. August 21 2023. Code End */}
                                            {/* Divya. June 21 2023. Code End */}
                                        </div> : null
                                }

                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <lable className="">Any other constraints?</lable><br />
                                <Toggle
                                    checked={formFields.constraints}
                                    icons={false}
                                    className='custom-toggle-classname'
                                    onChange={() => handlePhone(!formFields.constraints, 'constraints')}
                                    disabled={props.page == '/view-parse-rfx-detail' ? true : false} />
                                {
                                    formFields.constraints === true ?
                                        <div className="form-style mt-2">
                                            {/* Commenting the following code for WI-3. Divya. June 21 2023.. Code Start */}
                                            {/*<input type="text" className="form-control" style={{ height: "38px" }} onChange={handleChange} name="constraintsData" value={formFields['constraintsData']} disabled={props.page == '/view-parse-rfx-detail' ? true : false} />*/}
                                            {/* Commenting end. Divya. June 21 2023.. */}
                                            {/* Added this code for WI-3. Divya. June 21 2023.. Code Start */}
                                            {/* Commenting the following code for WOII-68. Divya. August 21 2023.. Code Start */}
                                            {/*<input type="text" className="form-control" style={{ height: "80px" }} onChange={handleChange} name="constraintsData" value={formFields['constraintsData']} disabled={props.page == '/view-parse-rfx-detail' ? true : false} /> */}
                                            {/* Commenting end. Divya. August 21 2023.. */}
                                            {/* Added this code for WOII-68. Divya. August 21 2023.. Code Start */}
                                            <textarea className="form-control" style={{ height: "100px", overflow: "auto" }} onChange={handleChange} name="constraintsData" value={formFields['constraintsData']} disabled={props.page == '/view-parse-rfx-detail' ? true : false} />
                                            {/* Divya. August 21 2023. Code End */}
                                            {/* Divya. June 21 2023. Code End */}
                                        </div> : null
                                }

                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <lable className="">Set aside for small business/minority firms?</lable><br />
                                <Toggle
                                    checked={formFields.business}
                                    icons={false}
                                    className='custom-toggle-classname'
                                    onChange={() => handlePhone(!formFields.business, 'business')}
                                    disabled={props.page == '/view-parse-rfx-detail' ? true : false} />
                                {
                                    formFields.business === true ?
                                        <div className="form-style mt-2">
                                            {/* Commenting the following code for WI-3. Divya. June 21 2023.. Code Start */}
                                            {/*<input type="text" className="form-control" style={{ height: "38px" }} onChange={handleChange} name="businessData" value={formFields['businessData']} disabled={props.page == '/view-parse-rfx-detail' ? true : false} />*/}
                                            {/* Commenting end. Divya. June 21 2023.. */}
                                            {/* Added this code for WI-3. Divya. June 21 2023.. Code Start */}
                                            {/* Commenting the following code for WOII-68. Divya. August 21 2023.. Code Start */}
                                            {/*<input type="text" className="form-control" style={{ height: "80px" }} onChange={handleChange} name="businessData" value={formFields['businessData']} disabled={props.page == '/view-parse-rfx-detail' ? true : false} />*/}
                                            {/* Commenting end. Divya. August 21 2023.. */}
                                            {/* Added this code for WOII-68. Divya. August 21 2023.. Code Start */}
                                            <textarea className="form-control" style={{ height: "100px", overflow: "auto" }} onChange={handleChange} name="businessData" value={formFields['businessData']} disabled={props.page == '/view-parse-rfx-detail' ? true : false} />
                                            {/* Divya. August 21 2023. Code End */}
                                            {/* Divya. June 21 2023. Code End */}
                                        </div> : null
                                }

                            </div>
                        </div>
                        <div className="col-md-12">
                            {/* Commenting the following code for WOII-73. Divya. September 01 2023.. Code Start */}
                            {/*<p className="font-medium">Buyer Information</p>*/}
                            {/* Commenting end. Divya. September 01 2023.. */}
                            {/* Added this code for  WOII-73. Divya. September 01 2023.. Code Start */}
                            <p className="font-20 font-bold">Buyer Information</p>
                            {/* Divya. September 01 2023. Code End */}
                        </div>
                        <div className="col-md-6 ">
                            <div className="form-group">
                                <label>Buyer Name</label>
                                <input type="text" className="form-control" onChange={handleChange} name="buyerName" value={formFields['buyerName']} disabled={props.page == '/view-parse-rfx-detail' ? true : false} />
                                <span className="text-danger">{flag === true && checkEmptyString(formFields.buyerName) ? 'Please Enter Buyer Name' : null}</span>
                            </div>
                        </div>
                        <div className="col-md-6 ">
                            <div className="form-group">
                                <label>Buyer Title</label>
                                <input type="text" className="form-control" onChange={handleChange} name="buyerTitle" value={formFields['buyerTitle']} disabled={props.page == '/view-parse-rfx-detail' ? true : false} />
                                <span className="text-danger">{flag === true && checkEmptyString(formFields.buyerTitle) ? 'Please Enter Buyer Title' : null}</span>
                            </div>
                        </div>
                        <div className="col-md-6 ">
                            <div className="form-group">
                                <label>Buyer Contact</label>
                                <PhoneInput
                                    placeholder="Enter phone number"
                                    value={formFields.buyerContact}
                                    className="form-control"
                                    name={"buyerContact"}
                                    onChange={e => handlePhone(e, "buyerContact")}
                                    countrySelectProps={{ unicodeFlags: true }}
                                    defaultCountry="US"
                                    international
                                    disabled={props.page == '/view-parse-rfx-detail' ? true : false}
                                />
                                <span className="text-danger">{flag === true && checkEmptyString(formFields.buyerContact) ? 'Please Enter Buyer Contact' : null}</span>
                            </div>
                        </div>
                        <div className="col-md-6 ">
                            <div className="form-group">
                                <label>Buyer Email</label>
                                <input type="text" className="form-control" onChange={handleChange} name="buyerEmail" value={formFields['buyerEmail']} disabled={props.page == '/view-parse-rfx-detail' ? true : false} />
                                <span className="text-danger">{flag === true && checkEmptyString(formFields.buyerEmail) ? 'Please Enter Buyer Email' : null}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-12">
                    {/* Commenting the following code for WOII-73. Divya. September 01 2023.. Code Start */}
                    {/*<p className="font-medium">RFx Attachments</p>*/}
                    {/* Commenting end. Divya. September 01 2023.. */}
                     {/* Added this code for  WOII-275. Prasanna. October 05 2024.. Code Start */}
                    <p className="font-20 font-bold"> Award Notification Process </p>
                    <div className="box-container" style={{ border: "3px solid #000", padding: "70px", marginBottom: "10px" }}>
                    </div>
                    {/* Prasanna. October 05 2024. Code End */}
                    {/* Added this code for  WOII-73. Divya. September 01 2023.. Code Start */}
                    <p className="font-20 font-bold">RFx Attachments</p>
                    {/* Divya. September 01 2023. Code End */}
                </div>
                {
                    props.page == '/view-parse-rfx-detail' ? null :
                        <div className="col-12 text-right">
                            <button type="button" className="btn btn-lg btn-brand-1 bg-success" onClick={handleShow}>Add</button>
                        </div>
                }
                <div className="row mb-3 justify-content-center">
                    <div className="col-12 my-3">
                        <table className="table table-style-1 table-responsive-lg mb-4 table ">

                            <tr>
                                <th>Document Type</th>
                                <th>File Name</th>
                                {/* <th>Version</th> */}
                                <th>Description</th>
                                <th>Action</th>
                            </tr>

                            {
                                addDocuments.map((res, i) => {
                                    return (
                                        <tr key={i}>
                                            <td>{res.document_type_name}</td>
                                            <td>{res.file_name}</td>
                                            {/* <td>{res.version}</td> */}
                                            <td>{res.description}</td>
                                            <td>
                                                <button type="button" className="btn btn-action mr-1" onClick={() => window.open(res.file, "_blank")}><i className="icofont-eye-alt"></i></button>
                                                <button type="button" className="btn btn-action edit mr-1" onClick={() => _onEdit(i, res)} disabled={props.page == '/view-parse-rfx-detail' ? true : false}><i className="icofont-pencil-alt-2"></i></button>
                                                <button type="button" className="btn btn-action delete" onClick={() => deleteDocument(i, res.id)} disabled={props.page == '/view-parse-rfx-detail' ? true : false}><i className="icofont-archive"></i></button>
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </table>
                    </div>
                    {
                        <div className="col-12">
                            <div className="card">
                                <div className="card-header">
                                    <h4 className="font-16 mb-0">Pre-RFx Comments</h4>
                                </div>
                                <div className="card-body bg-white">
                                    {
                                        commentData.length == 0 &&
                                        <div>No Comments Found</div>
                                    }
                                    {
                                        commentData.map((res, index) => {
                                            return (
                                                <div key={index} className="bg-light p-2 rounded mb-2">
                                                    <p className="mb-1">
                                                        <span className="font-medium mr-2">{res.user.first_name} {res.user.last_name}</span>
                                                        <span className="text-secondary font-12 mr-3">{moment(res.created_date).format("D-MMM-YYYY h:mm a")}</span>
                                                    </p>
                                                    {/* <div dangerouslySetInnerHTML={{ __html: res.comment }}></div> */}
                                                    <div><pre><p className="font-medium font-16">{res.comment}</p></pre></div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    }
                    {
                        props.page == '/view-parse-rfx-detail' ? null :
                            <div className="col-12 my-3 text-right">
                                <button type="button" className="btn btn-brand-1" onClick={() => save()} disabled={buttonLoader}> {buttonLoader && (
                                    <img src={require('../assets/css/images/button_loader.gif').default} alt="loading..." style={{ marginLeft: 5 }} />
                                )}
                                    <span>Save</span>
                                </button>
                            </div>
                    }
                </div>
            </Tab.Pane>

            {/* <DeleteModal
                showModalPopup={showDeleteModal}
                onPopupClose={isDeleteModal}
                index={deleteIndex}
                deleteData={deleteLocalDocument} /> */}

            <ModalPopup
                showModalPopup={showModalPopup}
                onPopupClose={isShowPopup}
                id={deleteId}
                endPoint={'/pre-rfx/document/'}
                updateList={getPreRfxData} />

            {/* MODAL */}
            <ReactModal
                initWidth={550}
                initHeight={480}
                top={'60%'}
                left={239}
                className={"contained-modal-title-vcenter"}
                onRequestClose={handleClose}
                isOpen={show}
                disableResize={true}>
                <div className="modal-header">
                    <h5 className="modal-title" id="">{editId ? 'Edit' : 'Add'} Documents</h5>
                    <button type="button" className="close" onClick={handleClose}>
                        <i className="icofont-close"></i>
                    </button>
                </div>
                <div className="modal-body">
                    <div className="row form-style">
                        <div className="col-md-12">
                            {/* <div className="form-group">
                                <label>Version</label>
                                <input type="text" className="form-control" onChange={handleChange} name="version" value={formFields['version']} />
                                <span className="text-danger">{modalFlag === true && formFields.version === '' ? 'Please Enter Version' : null}</span>
                            </div> */}
                            <div>
                                <div className="form-group">
                                    <label>Description</label>
                                    <textarea className="form-control h-auto" id="" cols="30" rows="3" onChange={handleChange} name="documentDescription" value={formFields['documentDescription']}></textarea>
                                    <span className="text-danger">{modalFlag === true && formFields.documentDescription === '' ? 'Please Enter Description' : null}</span>
                                </div>
                            </div>
                            <div className="form-group">
                                <label>Document Type</label>
                                <select id="disabledSelect" className="form-control custom-select" value={formFields['document_type_id']} name="document_type_id" onChange={handleChange}>
                                    <option value="" disabled selected>Select your option</option>
                                    {
                                        documentList.map((res) => {
                                            return (
                                                <option value={res.id}>{res.name}</option>
                                            )
                                        })
                                    }
                                </select>
                                <span className="text-danger">{modalFlag === true && formFields.document_type_id === '' ? 'Please Select Document Type' : null}</span>
                            </div>
                            <div className="form-group">
                                <label>Upload File</label>
                                <div className="custom-file">
                                    <input type="file" className="custom-file-input" id="inputGroupFile02" onChange={filehandler} />
                                    <label className="custom-file-label" for="inputGroupFile02" aria-describedby="inputGroupFileAddon02">{file === undefined ? 'Choose File' : file.name}</label>
                                    <span className="text-danger">{modalFlag === true && file === undefined ? 'Please Select file' : null}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-lg btn-brand-1 bg-danger" onClick={() => handleClose()}>Cancel</button>
                    <button type="button" className="btn btn-lg btn-brand-1" onClick={() => _onAddDocument()} disabled={buttonLoader}>
                        {buttonLoader && (
                            <img src={require('../assets/css/images/button_loader.gif').default} alt="loading..." style={{ marginLeft: 5 }} />
                        )}
                        <span>Save</span>
                    </button>
                </div>
            </ReactModal>


            <Fragment>
                <Modal show={showDeleteModal} onHide={() => setDeleteModal(!showDeleteModal)}
                    size="md"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="sign-in-title">
                            Alert
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ alignSelf: 'center' }}>
                        <div className="ml-5 mr-5">
                            <img src={require('../assets/css/images/cross.png').default} style={{ marginLeft: '37%' }} alt="No image found" id="img_hoder_1" width="80" />
                            <p style={{ fontSize: 20, textAlign: 'center' }} className="mt-4">Are You Sure ?</p>
                            <p style={{ textAlign: 'center' }}>Do you really want to archive these records? This process cannot be undone.</p>
                            <button type="button" className="btn btn-light mr-1" style={{ marginLeft: '30%' }} onClick={() => setDeleteModal(!showDeleteModal)}> Close</button>
                            <button type="button" className="btn btn-danger ml-3" onClick={() => deleteLocalDocument()}> Archive</button>
                        </div>
                    </Modal.Body>

                </Modal >
            </Fragment >

        </>
    )
}
export default RfxData;